import * as R from 'ramda'

export const capitalize = (text: string) => {
	return text.toLowerCase()
		.split(' ')
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ');
}

export const generateYearsArray = (range: number, top?: number) => {
	const upperLevel = top || new Date().getFullYear()

	return R.range(upperLevel - range, upperLevel + 1)
}

export const NAorBinary = (item?: boolean)  =>{
	return item === undefined ? '' : item ? 'Yes' : 'No'
}


// @ts-ignore
const safariCheck = !window.safari || (typeof window.safari !== 'undefined' && window.safari.pushNotification)
const safariObjCheck = ((p) => p.toString() === '[object SafariRemoteNotification]')(safariCheck)
// @ts-ignore
export const isSafari = /constructor/i.test(window.HTMLElement) || safariObjCheck

// @ts-ignore
export const isIE = /*@cc_on!@*/ false || !!document.documentMode
