import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './i18n';
import { formValidityPolyfill } from './iePolyfills'
import './index.css'




if (!HTMLFormElement.prototype.reportValidity) {
	formValidityPolyfill()
}

ReactDOM.render(
  <Suspense fallback="loading">
    <BrowserRouter basename={'/'}>
        <App />
    </BrowserRouter>
  </Suspense>,
	document.getElementById('root') as HTMLElement,
)

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.ready.then((registration) => {
		registration.unregister().catch((e) => console.log(e))
	})
}
