import axios from 'axios'

// import { ParticipantWithActivityCode } from '../../DataTypes/PersonalInfoTypes'
// import { fakeAPICall } from '../utils/testUtils'

const { REACT_APP_API_HOSTNAME } = process.env

const defaultErrorHandler = (e: Error) => {
	throw e
}

export const createNewParticipant = async (participantInfo: any) => {
	// if(NODE_ENV !== 'production') {
	// 	return await fakeAPICall(() => ({data: 'data', status: 201})).catch(defaultErrorHandler)
	// }

	return await axios.post(`${REACT_APP_API_HOSTNAME}/participant`, participantInfo).catch(defaultErrorHandler)
}
