import { AppBar, createStyles, IconButton, Theme, Toolbar, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import LanguageIcon from '@material-ui/icons/Language';
import Logo from '../../../Assets/b2w.png'

interface HeaderProps extends WithStyles<typeof styles> {
  t: any
	i18n: any
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
	const { classes, t, i18n } = props
  const changeLanguage = () => {
    const language = i18n.language
    if (language === 'en') {
      i18n.changeLanguage('es');
    } else {
      i18n.changeLanguage('en');
    }
  };

	return (
		<div className={classes.root}>
			<AppBar
				position="sticky"
				color="default"
				className={classes.appBar}
				data-auto="main-header"
				aria-label="RI Department of labor and training"
			>
				<Toolbar variant="dense" className={`${classes.layoutBreakpoints} ${classes.toolbar}`}>
					<nav className={classes.navigation} role="navigation" data-auto="navigation">
						<NavLink to="/" className={classes.branding} data-auto="logo">
							<div className={classes.logoContainer}>
								<img className={classes.logo} src={Logo} alt="dms logo" />
							</div>
						</NavLink>
					</nav>
					<div>
            <IconButton
              onClick={() => changeLanguage()}
              className={classes.language}
            >
              <LanguageIcon/>&nbsp;{t("oppositeLanguage")}
            </IconButton>
					</div>
				</Toolbar>
			</AppBar>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		appBar: {},
		toolbar: {},
		layoutBreakpoints: {
			margin: '0 auto',
			[theme.breakpoints.up('lg')]: {
				width: '80%',
			},
			[theme.breakpoints.down('md')]: {
				width: '95%',
			},
		},
		branding: {
			display: 'flex',
			alignItems: 'center',
			height: '50px',
			textDecoration: 'none',

			[theme.breakpoints.up('sm')]: {
				flexBasis: '50%',
			},
		},
		navigation: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between',
		},
		titleContainer: {
			maxHeight: '50px',
			overflow: 'hidden',
		},
		title: {
			lineHeight: 1,
			color: 'primary',
		},
		titleHead: {
			fontSize: `${theme.typography.fontSize}px`,
			letterSpacing: '2px',
			fontWeight: 'bolder',
			color: 'primary',
		},
		titleMid: {
			fontStyle: 'oblique',
			textTransform: 'uppercase',
			color: 'primary',
		},
		titleBottom: {
			fontWeight: 500,
		},
		language: {
			fontSize: `${theme.typography.fontSize}px`,
			fontWeight: 'bolder',
			color: 'primary',
      "&:hover": {
        backgroundColor: "transparent"
      }
		},
		logoContainer: {
			height: '55px',
		},
		logo: {
			height: '100%',
			width: 'auto',
			transform: 'scale(0.75, 0.75)',
		},
	})

export default withTranslation()(withStyles(styles)(Header))
