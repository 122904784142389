import {
	Button,
	createStyles,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Theme,
	Typography,
	withStyles,
	WithStyles,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NextIcon from '@material-ui/icons/NavigateNext'
import React from 'react'
import { withTranslation } from 'react-i18next'

interface InstructionsProps extends WithStyles<typeof styles> {
	activityName: string
	nextStep: () => void
  t: any
}

interface InstructionsState {
	expanded: number
}

class Instructions extends React.Component<InstructionsProps, InstructionsState> {
	public readonly state: InstructionsState = {
		expanded: 0,
	}

	handleChange = (panel: any) => (event: any, expanded: any) => {
		this.setState({ expanded: expanded ? panel : false })
	}

	render() {
		const { activityName, nextStep, t } = this.props
		const { classes } = this.props
		const { expanded } = this.state

		return (
			<section>
				<div className={classes.container}>
					<div className={classes.left}>
						<Typography variant="body1" component="p" className={classes.typography1}>
							{t("instructions.thanks")}
						</Typography>
						<Typography variant="h5" className={classes.activityInfo} align="center" data-auto="activity-name">
							{activityName}
						</Typography>
						<Typography component="p" variant="body1">
              {t("instructions.enrolled")}
						</Typography>

						<ul>
							<li>{t("instructions.name")}</li>
							<li>{t("instructions.dob")}</li>
							<li>{t("instructions.photo")}</li>
						</ul>

						<Typography component="p" variant="body1">
              {t("instructions.questions")}
						</Typography>
					</div>
					<div className={classes.right}>
						<ExpansionPanel expanded={expanded === 1} onChange={this.handleChange(1)} data-auto={`panel-${1}`}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="subtitle1" className={classes.question} data-auto={`panel-${1}-question`}>
                  {t("idInfo.kind")}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.details} data-auto={`panel-${1}-answer`}>
								<Typography component="div">
                  {t("idInfo.example")}
									<ul>
										<li>{t("identification.alien")}</li>
										<li>{t("identification.license")}</li>
										<li>{t("identification.passport")}</li>
										<li>{t("identification.school")}</li>
										<li>{t("identification.military")}</li>
									</ul>
								</Typography>
							</ExpansionPanelDetails>
						</ExpansionPanel>
						<ExpansionPanel expanded={expanded === 2} onChange={this.handleChange(2)} data-auto={`panel-${2}`}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="subtitle1" className={classes.question} data-auto={`panel-${2}-question`}>
                {t("idInfo.how")}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.details}>
								<Typography component="div" data-auto={`panel-${2}-answer`}>
                  {t("idInfo.easyWay")}
									<br /> <br />
									{t("idInfo.phoneOne")}
									{t("idInfo.phoneTwo")}
									<br /> <br />
									{t("idInfo.computer")}
									{t("idInfo.try")}
									<ul>
										<li>
                      {t("idInfo.optionOneLineOne")}
                      {t("idInfo.optionOneLineTwo")}
										</li>
										<li>
                      {t("idInfo.optionTwoLineOne")}
											{t("idInfo.optionTwoLineTwo")}
										</li>
									</ul>
									<br />
									{t("idInfo.please")}
									{t("idInfo.drag")}
									<br /><br />
									{t("idInfo.note")}
									<ul>
                    <li>{t("idInfo.jpeg")}</li>
                    <li>{t("idInfo.jpg")}</li>
                    <li>{t("idInfo.png")}</li>
                    <li>{t("idInfo.bmp")}</li>
                    <li>{t("idInfo.pdf")}</li>
                    <li>{t("idInfo.tiff")}</li>
									</ul>
								</Typography>
							</ExpansionPanelDetails>
						</ExpansionPanel>
						<ExpansionPanel expanded={expanded === 3} onChange={this.handleChange(3)} data-auto={`panel-${3}`}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="subtitle1" className={classes.question} data-auto={`panel-${3}-question`}>
                  {t("instructions.where")}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.details}>
								<Typography component="div" data-auto={`panel-${3}-answer`}>
                  {t("instructions.help")}
								</Typography>
							</ExpansionPanelDetails>
						</ExpansionPanel>
						<ExpansionPanel expanded={expanded === 4} onChange={this.handleChange(4)} data-auto={`panel-${4}`}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="subtitle1" className={classes.question} data-auto={`panel-${4}-question`}>
                  {t("instructions.why")}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.details}>
								<Typography component="div" data-auto={`panel-${4}-answer`}>
                  {t("instructions.required")}
									<br /> <br />
									{t("instructions.funding")}
								</Typography>
							</ExpansionPanelDetails>
						</ExpansionPanel>
						<ExpansionPanel expanded={expanded === 5} onChange={this.handleChange(5)} data-auto={`panel-${5}`}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="subtitle1" className={classes.question} data-auto={`panel-${5}-question`}>
                  {t("instructions.secure")}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.details}>
								<Typography component="div" data-auto={`panel-${5}-answer`}>
                  {t("instructions.state")}
									{/*To learn more
							about how we safely manage your information, please see our privacy policy.*/}
								</Typography>
							</ExpansionPanelDetails>
						</ExpansionPanel>
						<ExpansionPanel expanded={expanded === 6} onChange={this.handleChange(6)} data-auto={`panel-${6}`}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="subtitle1" className={classes.question} data-auto={`panel-${6}-question`}>
                  {t("instructions.released")}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.details}>
								<Typography component="div" data-auto={`panel-${6}-answer`}>
                  {t("instructions.information")}
									{/*To learn more
							about how we safely manage your information, please see our privacy policy.*/}
								</Typography>
							</ExpansionPanelDetails>
						</ExpansionPanel>
						<ExpansionPanel expanded={expanded === 7} onChange={this.handleChange(7)} data-auto={`panel-${7}`}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="subtitle1" className={classes.question} data-auto={`panel-${7}-question`}>
                  {t("instructions.documentation")}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.details}>
								<Typography component="div" data-auto={`panel-${7}-answer`}>
                  {t("instructions.please")}
								</Typography>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</div>
				</div>

				<div className={classes.buttonContainer}>
					<Button
						disabled={false}
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						data-auto="instructions-next-button"
						onClick={nextStep}
					>
						{t("instructions.now")}
						<NextIcon className={classes.icon} />
					</Button>
				</div>
			</section>
		)
	}
}

const styles = (theme: Theme) => {
	const SPACE = (spaces: number) => `${theme.spacing.unit * spaces}px`

	return createStyles({
		container: {
			display: 'flex',
			padding: '3%',
			justifyContent: 'space-between',
			flexWrap: 'wrap',
			overflow: 'auto',
			alignItems: 'stretch',
			flexShrink: 0,

			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},
		button: {
			margin: SPACE(2),
		},
		activityInfo: {
			backgroundColor: grey[200],
			padding: `${SPACE(1.5)} ${SPACE(2)}`,
			margin: SPACE(1.5),
			fontWeight: 500,
			[theme.breakpoints.down('sm')]: {
				flex: 1,
				textAlign: 'center',
			},
		},
		details: {
			backgroundColor: grey[50],
		},
		left: {
			flexBasis: '53%',
			[theme.breakpoints.down('sm')]: {
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				padding: `${theme.spacing.unit}px`,
			},
		},
		right: {
			flexBasis: '38%',
			[theme.breakpoints.down('sm')]: {
				flex: 1,
				marginTop: `${theme.spacing.unit * 2}px`,
			},
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			flex: 1,
			padding: `${theme.spacing.unit * 2.5}px 0`,
		},
		icon: {
			fontSize: theme.typography.fontSize,
			marginRight: theme.spacing.unit,
		},
		typography1: {
			flex: 1,
			textAlign: 'center',
		},
		question: {
			fontWeight: 500,
		},
	})
}

export default withTranslation()(withStyles(styles)(Instructions))
