import { Grow, Paper } from '@material-ui/core'
import React, { ReactNode } from 'react'

interface CustomSlideProps {
	slideStep: number
	currentStep: number
	style?: object
	children?: ReactNode
}

const CustomSlide: React.FC<CustomSlideProps> = (props: CustomSlideProps) => {
	const { slideStep, currentStep, style } = props

	return (
		<Grow
		timeout={350}
		in={currentStep === slideStep}
		mountOnEnter={true}
		unmountOnExit={true}
		exit={false}
		style={style}>
			<Paper elevation={4}>{props.children}</Paper>
		</Grow>
	)
}

export default CustomSlide
