import {
    Button,
    Checkbox,
    createStyles,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Theme,
    Typography,
    WithStyles,
    withStyles,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import NextIcon from '@material-ui/icons/NavigateNext'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { withTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'

import Ethnicity from '../../../DataTypes/Ethnicity'
import Gender from '../../../DataTypes/Gender'
import Language from "../../../DataTypes/Language";
import { FormData, StateIDs, StateInitials } from '../../../DataTypes/PersonalInfoTypes'
import Race from '../../../DataTypes/Race'
import * as regexPatterns from '../../../services/utils/patterns'
import DatePicker from '../../Templates/DatePicker/DatePicker'
import TextItem from '../FormTemplates/CustomTextItem/CustomTextItem'

interface PersonalInfoFormProps extends WithStyles<typeof styles> {
    next: () => void
    back: () => void
    updateParticipantHandler: (formData: FormData) => void
    participant: FormData,
  t:any
}

export class PersonalInfoForm extends React.PureComponent<PersonalInfoFormProps> {

    private formRef: React.RefObject<HTMLFormElement> = React.createRef()
    private phoneInputRef: React.RefObject<HTMLFormElement> = React.createRef()
  private ssnInputRef: React.RefObject<HTMLFormElement> = React.createRef()

    validate = () => {
        const form = this.formRef.current

        return form ? form.reportValidity() : false
    }

    onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const isFormValid = this.validate()

        if (isFormValid) {
            this.props.next();
        }
    }

    handleInputChange = (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData[field] = e.target.value;
        this.props.updateParticipantHandler(newParticipantData);
    }

    handleEthnicitySelect = (e: React.ChangeEvent<{}>) => {
        const { value } = e.target as HTMLSelectElement;

        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData.ethnicity = Ethnicity[value];
        this.props.updateParticipantHandler(newParticipantData);
    }

  handleLanguageSelect = (e: React.ChangeEvent<{}>) => {
    const { value } = e.target as HTMLSelectElement;

    const newParticipantData = {
      ...this.props.participant
    }
    newParticipantData.preferredLanguage = Language[value];
    this.props.updateParticipantHandler(newParticipantData);
  }

    handleGenderSelect = (e: React.ChangeEvent<{}>) => {
        const { value } = e.target as HTMLSelectElement;
        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData.gender = Gender[value];
        this.props.updateParticipantHandler(newParticipantData);
    }

    handleRadioSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target
        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData[name] = value;
        this.props.updateParticipantHandler(newParticipantData);
    }

    handleRaceSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, value } = e.target
        const newParticipantData = {
            ...this.props.participant
    }

        if (checked === true) {
            if (value === Race.NA.key) {
              newParticipantData.races = [];
      } else {
        newParticipantData.races = newParticipantData.races.filter((r: Race) => {
          return r.key !== Race.NA.key;
        })
      }
      newParticipantData.races.push(Race[value]);
        } else {
            newParticipantData.races = newParticipantData.races.filter((r: Race) => {
                return r.key !== value;
            })
        }
        this.props.updateParticipantHandler(newParticipantData);
    }

    handleDateOfBirthChange = (date: Date) => {
        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData.dateOfBirth = date;
        this.props.updateParticipantHandler(newParticipantData);
    }

    toggleHomelessStatus = () => {
        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData.homeless = !newParticipantData.homeless;
        this.props.updateParticipantHandler(newParticipantData);
    }

    toggleAlienStatus = () => {
        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData.alien = !newParticipantData.alien;
        this.props.updateParticipantHandler(newParticipantData);
    }

    toggleMailingAddressStatus = () => {

        const sameAsHomeAddress = !this.props.participant.sameAsHomeAddress

        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData.sameAsHomeAddress = sameAsHomeAddress;

        if(sameAsHomeAddress){
            newParticipantData.mailingAddress1 = newParticipantData.address1;
            newParticipantData.mailingAddress2 = newParticipantData.address2;
            newParticipantData.mailingCity = newParticipantData.city;
            newParticipantData.mailingState = newParticipantData.state;
            newParticipantData.mailingZip = newParticipantData.zip;
        }else{
            newParticipantData.mailingAddress1 = undefined;
            newParticipantData.mailingAddress2 = undefined;
            newParticipantData.mailingCity = undefined;
            newParticipantData.mailingState = StateIDs.RI;
            newParticipantData.mailingZip = undefined;
        }
        this.props.updateParticipantHandler(newParticipantData);
    }

    handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData.phoneNumber = e.target.value;
        this.props.updateParticipantHandler(newParticipantData);
    }

    handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newParticipantData = {
            ...this.props.participant
        }
        newParticipantData.ssn = e.target.value;
        this.props.updateParticipantHandler(newParticipantData);
    }

    phoneMaskCustom = (props: any) => {
        return <InputMask {...props} ref={this.phoneInputRef} mask="999-999-9999" maskChar={null} />
    }

    SSNMaskCustom = (props: any) => {
        return <InputMask {...props} ref={this.ssnInputRef} mask="999-99-9999" maskChar={null} />
    }

    render() {
        const { classes, participant, t } = this.props

        return (
            <div className={classes.root}>
                <form onSubmit={this.onFormSubmit} ref={this.formRef} className={classes.signUpForm} autoComplete="off">
                    <Typography variant="h5">{t("personalInfoForm.sectionTitle")}</Typography>

                    <div className={classNames(classes.sectionContainer, classes.personalInfoSection)}>
                        <div className={classes.group} style={{ justifyContent: 'space-between' }}>
                            <Grid container={true} spacing={16}>
                                <Grid item={true} xs={12} md={5}>
                                    <TextItem
                                        label={t("personalInfoForm.label.first")}
                                        prop="firstName"
                                        value={this.props.participant.firstName || ""}
                                        required={true}
                                        onChange={this.handleInputChange}
                                        otherInputProps={{
                                            maxLength: 255,
                                            pattern: regexPatterns.name,
                                            autoFocus: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={2}>
                                    <TextItem
                                        label={t("personalInfoForm.label.middle")}
                                        value={participant.middleInitial || ""}
                                        prop="middleInitial"
                                        otherInputProps={{
                                            maxLength: 1,
                                            pattern: regexPatterns.singleLetter,
                                            title: t("personalInfoForm.validation.middle")
                                        }}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={5}>
                                    <TextItem
                                        label={t("personalInfoForm.label.last")}
                                        prop="lastName"
                                        value={participant.lastName || ""}
                                        required={true}
                                        onChange={this.handleInputChange}
                                        otherInputProps={{
                                            maxLength: 255,
                                            pattern: regexPatterns.name,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <div className={classes.group} style={{ justifyContent: 'space-between' }}>
                            <Grid container={true} spacing={16}>
                                <Grid item={true} xs={12} md={5}>
                                    <DatePicker
                                        id="dob"
                                        required={true}
                                        label={t("personalInfoForm.label.dob")}
                                        onChange={this.handleDateOfBirthChange}
                                        value={participant.dateOfBirth}
                                        minDate={moment().subtract(120, 'years').toDate()}
                                        maxDate={moment().subtract(10, 'years').toDate()}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={2} >
                                    <div className={classes.alianLabel}>
                                    <FormGroup row={true}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={participant.alien || false}
                                                    onChange={this.toggleAlienStatus}
                                                    color="primary"
                                                    data-auto={'checkbox-for-alien'}
                                                />
                                            }
                                            label={t("personalInfoForm.label.alien")}
                                        />
                                    </FormGroup>

                                    </div>
                                    
                                </Grid>
                                <Grid item={true} xs={12} md={5}>
                                    <TextField
                                        id="ssn"
                                        required={!participant.alien}
                                        value={participant.ssn || ''}
                                        name="ssn"
                                        placeholder="XXX-XX-XXXX"
                                        margin="normal"
                                        fullWidth={true}
                                        label={t("personalInfoForm.label.ssn")}
                                        variant="outlined"
                                        InputProps={{
                                            inputProps: {
                                                title: t("personalInfoForm.validation.ssn"),
                                                className: classes.input,
                                                name: 'ssn',
                                                pattern: '^\\d{3}-?\\d{2}-?\\d{4}$',
                                                ref: this.ssnInputRef,
                                            },
                                            inputComponent: this.SSNMaskCustom,
                                        }}
                                        onChange={this.handleSSNChange}
                                    />
                                    
                                </Grid>
                            </Grid>
                        </div>              
                        <div className={classes.group} style={{ justifyContent: 'space-between' }}>
                            <FormControl className={classes.inputSelect} data-auto="gender group" required={true}>
                                <FormLabel component="label" className={classes.label}>
                  {t("personalInfoForm.label.gender")}:
                                </FormLabel>
                                <RadioGroup
                                    aria-label="gender"
                                    data-auto="gender"
                                    name="gender"
                                    className={classes.radioGroup}
                                    value={participant.gender ? participant.gender.key : ''}
                  onChange={this.handleGenderSelect}
                                >
                  {Gender.all().map((item: Gender, index: number) => (
                    <FormControlLabel
                      key={index}
                      value={item.key}
                      control={<Radio id={`gender-${index}`} color="primary" data-auto={`radio-for-${item.label}`} required={true} />}
                      label={t(item.label)}
                    />
                  ))}
                                </RadioGroup>
                            </FormControl>
                            <FormControl className={classes.inputSelect} data-auto="ethnicity group" required={true}>
                                <FormLabel component="label" className={classes.label}>
                  {t("personalInfoForm.label.ethnicity")}:
                                </FormLabel>
                                <RadioGroup
                                    aria-label="ethnicity"
                                    data-auto="ethnicity"
                                    name="ethnicity"
                                    className={classes.radioGroup}
                                    value={participant.ethnicity ? participant.ethnicity.key : ''}
                                    onChange={this.handleEthnicitySelect}
                                >
                                    {Ethnicity.all().map((item: Ethnicity, index: number) => (
                                        <FormControlLabel
                                            key={index}
                                            value={item.key}
                                            control={<Radio id={`ethnicity-group-${index}`} color="primary" data-auto={`radio-for-${item.label}`} required={true} />}
                                            label={t(item.label)}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            <FormControl className={classes.inputSelect} data-auto="race group" required={true}>
                  <FormLabel component="label" className={classes.label}>
                    {t("personalInfoForm.label.race")}:
                  </FormLabel>

                  {Race.all().map((item: Race, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          id={`race-group-${index}`}
                          color="primary"
                          checked={participant.races.indexOf(Race[item.key]) >= 0}
                          onChange={this.handleRaceSelect}
                          name={`race-${item.key}`}
                          value={item.key}
                          data-auto={`checkbox-for-${item.label}`}
                          required={participant.races === null || participant.races.length === 0}
                        />
                      }
                      label={t(item.label)}
                    />
                  ))}
              </FormControl>
              <FormControl className={classes.inputSelect} data-auto="language group">
                <FormLabel component="label" className={classes.label}>
                  {t("personalInfoForm.label.language")}:
                </FormLabel>
                <RadioGroup
                  aria-label="language"
                  data-auto="language"
                  name="language"
                  className={classes.radioGroup}
                  value={participant.preferredLanguage ? participant.preferredLanguage.key : ''}
                  onChange={this.handleLanguageSelect}
                >
                  {Language.all().map((item: Language, index: number) => (
                    <FormControlLabel
                      key={index}
                      value={item.key}
                      control={<Radio id={`language-${index}`} color="primary" data-auto={`radio-for-${item.label}`} />}
                      label={t(item.label)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
                    </div>

                    <Divider variant="middle" style={{ margin: '12px 0' }} />

                    <Grid container={true} spacing={16}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography variant="h6" component="h3" align="center" data-auto="Main Address Label">
                {t("personalInfoForm.label.homeAddress")}
                            </Typography>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={participant.homeless || false}
                                            onChange={this.toggleHomelessStatus}
                                            color="primary"
                                            data-auto={'checkbox-for-homeless'}
                                        />
                                    }
                                    label={t("personalInfoForm.label.homeless")}
                                />
                            </FormGroup>
                            <div className={classes.group} data-auto="Main Address">
                                <Grid container={true} spacing={16}>
                                    <Grid item={true} xs={12}>
                                        <TextItem
                                            required={!participant.homeless}
                                            label={t("personalInfoForm.label.addressLineOne")}
                                            prop="address1"
                                            value={participant.address1 || ''}
                                            onChange={this.handleInputChange}
                                            otherInputProps={{
                                                maxLength: 100,
                                                pattern: regexPatterns.name,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <TextItem
                                            label={t("personalInfoForm.label.addressLineTwo")}
                                            prop="address2"
                                            value={participant.address2 || ''}
                                            onChange={this.handleInputChange}
                                            otherInputProps={{
                                                maxLength: 100,
                                                pattern: regexPatterns.name,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <TextItem
                                            required={!participant.homeless}
                                            label={t("personalInfoForm.label.city")}
                                            prop="city"
                                            value={participant.city || ''}
                                            onChange={this.handleInputChange}
                                            otherInputProps={{
                                                maxLength: 45,
                                                pattern: regexPatterns.name,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={6}>
                                        <TextItem
                                            required={true}
                                            label={t("personalInfoForm.label.zip")}
                                            prop="zip"
                                            value={participant.zip || ''}
                                            onChange={this.handleInputChange}
                                            otherInputProps={{
                                                maxLength: 15,
                                                pattern: regexPatterns.zip,
                                                title: t("personalInfoForm.validation.zip"),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={6}>
                                        <TextItem
                                            isSelect={true}
                                            required={!participant.homeless}
                                            label={t("personalInfoForm.label.state")}
                                            prop="state"
                                            value={participant.state || StateIDs[StateIDs.RI]}
                                            onChange={this.handleInputChange}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        >
                                            {StateInitials.map((stateId, index) => (
                                                <option key={index} value={stateId} data-auto={`state-${stateId}`}>
                                                    {stateId}
                                                </option>
                                            ))}
                                        </TextItem>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Typography variant="h6" component="h3" align="center" data-auto="Mailing Address Label">
                                {t("personalInfoForm.label.mailingAddress")}
                            </Typography>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="mailing-address-checkbox"
                                            checked={participant.sameAsHomeAddress || false}
                                            onChange={this.toggleMailingAddressStatus}
                                            color="primary"
                                            data-auto={'checkbox-for-same-as-home-address'}
                                        />
                                    }
                                    label={t("personalInfoForm.label.sameAsHomeAddress")}
                                />
                            </FormGroup>
                            <div className={classes.group} data-auto="Main Address">
                                <Grid container={true} spacing={16}>
                                    <Grid item={true} xs={12}>
                                        <TextItem
                                            label={t("personalInfoForm.label.mailingAddressLineOne")}
                                            prop="mailingAddress1"
                                            value={participant.mailingAddress1 || ''}
                                            onChange={this.handleInputChange}
                                            otherInputProps={{
                                                maxLength: 100,
                                                pattern: regexPatterns.name,
                                                disabled: !!participant.sameAsHomeAddress,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <TextItem
                                            label={t("personalInfoForm.label.mailingAddressLineTwo")}
                                            prop="mailingAddress2"
                                            value={participant.mailingAddress2 || ''}
                                            onChange={this.handleInputChange}
                                            otherInputProps={{
                                                maxLength: 100,
                                                pattern: regexPatterns.name,
                                                disabled: participant.sameAsHomeAddress,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <TextItem
                                            label={t("personalInfoForm.label.mailingCity")}
                                            prop="mailingCity"
                                            value={participant.mailingCity || ''}
                                            onChange={this.handleInputChange}
                                            otherInputProps={{
                                                maxLength: 45,
                                                pattern: regexPatterns.name,
                                                disabled: participant.sameAsHomeAddress,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={6}>
                                        <TextItem
                                            label={t("personalInfoForm.label.mailingZip")}
                                            prop="mailingZip"
                                            value={participant.mailingZip || ''}
                                            onChange={this.handleInputChange}
                                            otherInputProps={{
                                                maxLength: 15,
                                                pattern: regexPatterns.zip,
                                                disabled: participant.sameAsHomeAddress,
                        title: t("personalInfoForm.validation.mailingZip")
                                            }}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={6}>
                                        <TextItem
                                            isSelect={true}
                                            label={t("personalInfoForm.label.mailingState")}
                                            prop="mailingState"
                                            value={participant.mailingState || StateIDs[StateIDs.RI]}
                                            onChange={this.handleInputChange}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            otherInputProps={{
                                                disabled: participant.sameAsHomeAddress,
                                            }}
                                        >
                                            {StateInitials.map((stateId, index) => (
                                                <option key={index} value={stateId} data-auto={`state-${stateId}`}>
                                                    {stateId}
                                                </option>
                                            ))}
                                        </TextItem>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                    <Divider variant="middle" style={{ margin: '12px 0' }} />

                    <div className={classes.sectionContainer} data-auto="contact-details-section">
                        <Typography variant="h6" component="h3" data-auto="contact-details-header">
                            {t("personalInfoForm.label.contactDetails")}
                        </Typography>

                        <Grid container={true} spacing={16}>
                            <Grid item={true} xs={12} md={6}>
                                <TextItem
                                    label={t("personalInfoForm.label.emailAddress")}
                                    prop="email"
                                    value={participant.email || ''}
                                    onChange={this.handleInputChange}
                                    otherInputProps={{
                                        placeholder: 'example@domain.com',
                                        type: 'email',
                                    }}
                                />

                                <Typography variant="caption" gutterBottom={true} style={{ color: grey[700] }}>
                  {t("personalInfoForm.emailConfirmationDiaglog")}
                                </Typography>
                            </Grid>

                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    fullWidth={true}
                                    label={t("personalInfoForm.label.phoneNumber")}
                                    id="phone-number"
                                    type="tel"
                                    margin="normal"
                                    placeholder="123-456-7890"
                                    value={participant.phoneNumber || ''}
                                    variant="outlined"
                                    onChange={this.handlePhoneNumberChange}
                                    InputProps={{
                                        inputProps: {
                      title: t("personalInfoForm.validation.phoneNumber"),
                                            name: 'phoneNumber',
                                            ref: this.phoneInputRef,
                                            pattern: regexPatterns.phone,
                                        },
                                        inputComponent: this.phoneMaskCustom,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    <Divider variant="middle" style={{ margin: '12px 0' }} />

                    <div className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {this.props.back();}}
                            className={classes.button}
                            data-auto="file-upload-back-button"
                        >
                            <PrevIcon className={classes.icon} />
              {t("common.goBack")}
                        </Button>

                        <Button
                            disabled={false}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            data-auto="personal-info-next-button"
                            type="submit"
                        >
              {t("common.next")}
                            <NextIcon className={classes.icon} />
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: '3%',
        },
        signUpForm: {
            display: 'flex',
            flexDirection: 'column',
        },
        sectionContainer: {},
        personalInfoSection: {
            flexWrap: 'wrap',
        },
        input: {},
        icon: {
            fontSize: theme.typography.fontSize,
            marginRight: theme.spacing.unit,
        },
        group: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        dob: {
            flexBasis: '20%',
            minWidth: '250px',
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                minWidth: '100px',
            },
        },
        menu: {},
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            marginLeft: `${theme.spacing.unit * 2}px`,
        },
        contactItem: {
            minHeight: '65px',
            flexBasis: '48%',

            [theme.breakpoints.down('sm')]: {
                flexBasis: '98%',
            },
        },
        inputSelect: {
            flexDirection: 'row',

            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                marginTop: `${theme.spacing.unit * 2}px`,
                flexBasis: '50%',
            },

            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
            },
        },
        personalInfoItem: {},
        radioGroup: {
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        genderLabel: {
            alignSelf: 'center',
            marginRight: theme.spacing.unit * 5,

            [theme.breakpoints.down('md')]: {
                marginRight: theme.spacing.unit * 2,
            },
        },
        label: {
            alignSelf: 'center',
            marginRight: theme.spacing.unit * 5,

            [theme.breakpoints.down('md')]: {
                marginRight: theme.spacing.unit * 2,
            },
        },
        alianLabel:{
            padding: '16px 0px'
        }
    })

export default withTranslation()(withStyles(styles)(PersonalInfoForm))