import React from 'react'
import { isIE, isSafari } from '../../../services/utils/utils'
import CompatibilityDatePicker from './CompatibilityDatePicker'
import DefaultDatePicker from './DefaultDatePicker'

interface DatePickerProps {
	required: boolean
	id: string
	label?: string
	value?: Date
	onChange: (date: Date) => void
	minDate?: Date
	maxDate?: Date
}


export default class DatePicker extends React.Component<DatePickerProps> {
	public render() {

		if(isIE || isSafari){
			return (
				<div>
					<CompatibilityDatePicker 
						value={this.props.value}
						id={this.props.id}
						label={this.props.label}
						required={this.props.required}
						onChange={this.props.onChange}
						minDate={this.props.minDate}
						maxDate={this.props.maxDate}
					/>
				</div>)
		}else{
			return (
				<div>
					<DefaultDatePicker 
						value={this.props.value}
						id={this.props.id}
						label={this.props.label}
						required={this.props.required}
						onChange={this.props.onChange}
						minDate={this.props.minDate}
						maxDate={this.props.maxDate}
					/>
				</div>)
		}
	}
}