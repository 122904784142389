import {
  Button,
  createStyles,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import PhotoIcon from '@material-ui/icons/InsertPhoto'
import * as React from 'react'
import { withTranslation } from 'react-i18next'

interface IDInfoProps extends WithStyles<typeof styles> {
  handleFileRemove: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  docName: string
  children: {
    docTypePicker: React.ReactNode
  }
  t: any
}

const IDInfo: React.FC<IDInfoProps> = (props: IDInfoProps) => {
  const { children, classes, docName, handleFileRemove, t } = props

  return (
    <Paper elevation={2} className={classes.root}>
      <Grid container={true} spacing={8} style={{ padding: '10px' }}>
        <Grid item={true} xs={2}>
          <PhotoIcon className={classes.photoIcon} />
        </Grid>
        <Grid item={true} xs={10} container={true} direction="column">
          <Grid item={true} xs={true} container={true} spacing={8} justify="space-between">
            <Typography variant="subtitle1" className={classes.subtitle}>
              {docName}
            </Typography>

            <Button
              size="small"
              variant="contained"
              color="secondary"
              className={classes.button}
              title={t("filePreview.title")}
              aria-label="Remove"
              onClick={handleFileRemove}
            >
              <DeleteIcon style={{ marginRight: '4px', alignSelf: "middle" }} /> Remove
             </Button>
          </Grid>

          <Divider variant="middle" style={{ margin: '15px 0' }} />

          <Grid item={true} xs={true} container={true} direction="column" spacing={8}>
            {children.docTypePicker}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#ececec",
      display: 'flex',
      margin: '2rem 0'
    },
    button: {},
    subtitle: {
      display: 'inline-block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '55%'
    },
    photoIcon: {
      minHeight: "100%",
      minWidth: "100%",
      color: '#a1a1a1'
    }
  })

export default withTranslation()(withStyles(styles)(IDInfo))
