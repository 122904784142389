export default class Ethnicity {
  static readonly NA  = new Ethnicity('NA', "common.dnd");
  static readonly LATINO = new Ethnicity('LATINO', "ethnicity.latino");
  static readonly NOT_LATINO  = new Ethnicity('NOT_LATINO', "ethnicity.notLatino");


  static all(){
  	return [
  		Ethnicity.LATINO,
  		Ethnicity.NOT_LATINO,
      Ethnicity.NA,
  	]
  }

  // private to disallow creating other instances of this type
  private constructor(public readonly key: string, public readonly label: any) {
  }

  toString() {
    return this.key;
  }


}
