import {
	Button,
	Checkbox,
	createStyles,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
  Link,
	/* Radio,
	RadioGroup, */
	Theme,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import i18n from "i18next";
import moment from 'moment'
import React from 'react'
import {withTranslation} from 'react-i18next'
import SurveyRadioQuestion from 'src/components/Forms/SurveyData/SurveyRadioQuestion';
import {FormData} from '../../../DataTypes/PersonalInfoTypes'
import Race from '../../../DataTypes/Race'
import SurveyAnswer from '../../../DataTypes/SurveyAnswer'

interface ParticipantInfoProps extends WithStyles<typeof styles> {
	participant: FormData
	updateParticipantHandler: (formData: FormData) => void
	back: () => void
	next: () => void
	locked: boolean
	participantType?: number
	backToWork?: boolean
  t: any
}

export class ParticipantInfo extends React.PureComponent<ParticipantInfoProps> {

	onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
    const newParticipantData = {
      ...this.props.participant
    }
    newParticipantData.formLanguage = i18n.language;
    this.props.updateParticipantHandler(newParticipantData);
		this.props.next();
	}

	goBack = () => {
		const newParticipantData = {
			...this.props.participant
		}
		newParticipantData.acceptedTerms = false;
		this.props.updateParticipantHandler(newParticipantData);
		this.props.back();
	}

	handleSurveyChange = (e: React.ChangeEvent<{}>) => {
		const { name, value } = e.target as HTMLSelectElement | HTMLInputElement;

		const newParticipantData = {
			...this.props.participant
		}

		newParticipantData[name] = SurveyAnswer[value];
		this.props.updateParticipantHandler(newParticipantData);
	}

	handleAcceptTerms = () => {
		const newParticipantData = {
			...this.props.participant
		}
		newParticipantData.acceptedTerms = !newParticipantData.acceptedTerms;
		this.props.updateParticipantHandler(newParticipantData);
	}

	displayCitizen = (citizenshipType: string | undefined) => {
		if (citizenshipType === '1'){
			return "surveyAnswer.yes"
		} else if (citizenshipType === '2' || citizenshipType === '3' || citizenshipType === '4'){
			return "surveyAnswer.no"
		}
		return "common.blank"
	}


	displayBoolean = (b: boolean | undefined) => {
		if(b === true){
			return "surveyAnswer.yes";
		} else if(b === false){
			return "surveyAnswer.no"
		}
		return "common.blank";
	}

	render() {
		const { classes, participant, backToWork, t } = this.props
    const bulletPoints = []
     for(let i=1;i<=4;i++){
       bulletPoints.push(t(`participantInfo.covidExperienced${i}`))
	     }
		return (
				<div className={classes.root} data-auto="review-container">
					<div className={classes.infoHeader}>
						<Typography variant="h4" component="h1" align="center" data-auto="review-header">
            {t("participantInfo.review")}
						</Typography>
					</div>
					<Divider variant="middle" className={classes.divider} />

					<div className={classes.infoContainer}>
						{[
							{"label": t("personalInfoForm.label.first"), value: participant.firstName},
							{"label": t("personalInfoForm.label.middle"), value: participant.middleInitial},
							{"label": t("personalInfoForm.label.last"), value: participant.lastName},
							{"label": t("personalInfoForm.label.gender"), value: participant.gender ? t(participant.gender.label) : ''},
							{"label": t("personalInfoForm.label.ethnicity"), value: participant.ethnicity ? t(participant.ethnicity.label) : ''},
							{"label": t("personalInfoForm.label.race"),
								value: participant.races.map((r: Race) => {
									return t(r.label);
								})
								.join(", ")},
							{"label": t("participantInfo.dob"), value: moment(participant.dateOfBirth).format('MM/DD/YYYY')},
							{"label": t("personalInfoForm.label.phoneNumber"), value: participant.phoneNumber},
							{"label": t("personalInfoForm.label.emailAddress"), value: participant.email},
							{"label": t("participantInfo.homeless"), value: t(this.displayBoolean(participant.homeless))},
							{"label": t("personalInfoForm.label.homeAddress"), value: participant.address1},
							{"label": t("participantInfo.addressLineTwo"), value: participant.address2},
							{"label": t("personalInfoForm.label.city"), value: participant.city},
							{"label": t("participantInfo.zip"), value: participant.zip},
							{"label": t("personalInfoForm.label.state"), value: participant.state},
							{"label": t("personalInfoForm.label.mailingAddress"), value: participant.mailingAddress1},
							{"label": t("personalInfoForm.label.mailingAddressLineTwo"), value: participant.mailingAddress2},
							{"label": t("personalInfoForm.label.mailingCity"), value: participant.mailingCity},
							{"label": t("participantInfo.mailingZip"), value: participant.mailingZip},
							{"label": t("personalInfoForm.label.mailingState"), value: participant.mailingState},
						].map((item: any, index: number) => (
							<div className={classes.infoItem} key={index}>
								<Typography
									variant="h6"
									component="h3"
									style={{ display: 'inline-block', paddingRight: '15px' }}
									data-auto={`label-for-${item.label}`}
								>
									{item.label} <span>&#58;</span>
								</Typography>
								<Typography variant="body1" style={{ display: 'inline-block' }} data-auto={`${item.label}`}>
									{item.value}
								</Typography>
							</div>
						))}
					</div>

					<Divider variant="middle" className={classes.divider} />

					<div className={classes.infoContainer}>
						<div className={classes.infoItem}>
							<Typography
								variant="h6"
								component="h3"
								style={{ display: 'inline-block', paddingRight: '15px' }}
								data-auto="ssn-label"
							>
								{t("personalInfoForm.label.ssn")}:
							</Typography>
							<Typography variant="body1" style={{ display: 'inline-block' }} data-auto="ssn">
								{this.props.participant.ssn}
							</Typography>
						</div>

						<div className={classes.infoItem}>
							<Typography
								variant="h6"
								component="h3"
								style={{ display: 'inline-block', paddingRight: '15px' }}
								data-auto="id-doc-label"
							>
								{t("participantInfo.identification")}:
							</Typography>
							{participant.idDoc && participant.idDoc.name && (
								<Typography variant="body1" style={{ display: 'inline-block' }} data-auto="id-doc">
									{participant.idDoc.name}
								</Typography>
							)}
						</div>
					</div>

					<Divider variant="middle" className={classes.divider} />


					<div className={classes.infoContainer}>
						{[
							{"label": t("participantInfo.citizen"), value: t(this.displayCitizen(participant.citizenshipType))},
							{"label": t("participantInfo.alienNumber"), value: participant.alienNumber},
							{"label": t("participantInfo.alienDate"),
								value: participant.alienRegistrationExpirationDate ?
									moment(participant.alienRegistrationExpirationDate).format('MM/DD/YYYY') : ''},
							{"label": t("participantInfo.veteran"),
								value: participant.veteran ? t(participant.veteran.label) : ''},
              {"label": t("participantInfo.disabilityStatus"),
                value: participant.disabilityStatus ? t(participant.disabilityStatus.label) : ''},
							{"label": t("participantInfo.ell"),
								value: participant.ell ? t(participant.ell.label) : ''},
							{"label": t("participantInfo.highest"), value: participant.education ? t(participant.education.label) : ''},
							{"label": t("participantInfo.last"), value: participant.highestGrade},
							{"label": t("participantInfo.inSchool"),
								value: participant.attendingSchool ? t(participant.attendingSchool.label) : ''},
							{"label": t("participantInfo.schoolName"), value: participant.currentSchool},
							{"label": t("participantInfo.employed"),
								value: participant.currentlyEmployed ? t(participant.currentlyEmployed.label) : ''},
							{"label": t("participantInfo.employmentType"), value: participant.employmentType ? t(participant.employmentType.label) : ''},
							{"label": t("participantInfo.employer"), value: participant.employer},
							{"label": t("participantInfo.laidOff"),
								value: participant.beingLaidOff ? t(participant.beingLaidOff.label) : ''},
							{"label": t("participantInfo.layOffDate"),
								value: participant.layoffDate ? moment(participant.layoffDate).format('MM/DD/YYYY') : ''},
							{"label": t("participantInfo.layOffEmployer"), value: participant.layoffEmployer},
							{"label": t("participantInfo.higherWages"),
								value: participant.higherWage ? t(participant.higherWage.label) : ''},
							{"label": t("participantInfo.skills"),
								value: participant.betterSkills ? t(participant.betterSkills.label) : ''},
							{"label": t("participantInfo.familyMember"),
								value: participant.familyDependent ? t(participant.familyDependent.label) : ''}
						].map((item: any, index: number) => {
							return (
								<div key={index} style={{ flexBasis: '100%' }}>
									<Typography
										variant="h6"
										component="h3"
										className={classes.inlineItem}
										style={{ paddingRight: '15px' }}
										data-auto={`label-for-${item.label}`}
									>
										{item.label} <span>&#58;</span>
									</Typography>
									<Typography variant="body1" className={classes.inlineItem} data-auto={`${item.label}`}>
										{item.value || ''}
									</Typography>
								</div>
							)
						})}
						{ backToWork && (<div style={{ flexBasis: '100%' }}>
 								<Typography
 									variant="h6"
 									component="h3"
 									className={classes.inlineItem}
 									style={{ paddingRight: '15px' }}
 									data-auto={`label-for-covid`}
 								>
 									{t("participantInfo.covid")} <span>&#58;</span>
 								</Typography>
 								<Typography variant="body1" className={classes.inlineItem} data-auto={`covid`}>
 									{participant.covid ? t(participant.covid.label) : ''}
 								</Typography>
 							</div>)
 						}
					</div>

          <Divider variant="middle" className={classes.divider} />
 
          <SurveyRadioQuestion
            title={t("participantInfo.covidExperienced")}
              propName="covid"
            onChange={this.handleSurveyChange}
            options={ [
                { value: SurveyAnswer.YES.key, label: t(SurveyAnswer.YES.label) },
                { value: SurveyAnswer.NO.key, label: t(SurveyAnswer.NO.label) }
              ]
            }
            value={participant.covid ? participant.covid.key : ''}
            required={true}
            bulletPoints={bulletPoints}
          />

					<Divider variant="middle" className={classes.divider} />

					<form className={classes.form} onSubmit={this.onFormSubmit}>
						<Typography variant="h6" component="h3" data-auto="disclaimer title">
            {t("participantInfo.attestation")}&#58;
						</Typography>
						<div className={classes.disclaimer}>
							<FormControl component="div" className={classes.formControl}>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={participant.acceptedTerms}
												onChange={this.handleAcceptTerms}
												name="acceptTermsCheckbox"
												data-auto="disclaimer-checkbox"
												disabled={this.props.locked}
											/>
										}
										label={
                      <div>
                        <p>{t("participantInfo.disclaimerAttestation.partOne")}</p>
                        <p>
                          <span>{t("participantInfo.disclaimerAttestation.partTwoA")}</span>
                          <Link href='https://employri.org' target='_blank'>EmployRI.org</Link>
                          <span>{t("participantInfo.disclaimerAttestation.partTwoB")}</span>
                        </p>
                        <p>{t("participantInfo.disclaimerAttestation.partThree")}</p>
                      </div>
                    }
										className={classes.formLabel}
									/>
								</FormGroup>
							</FormControl>
						</div>
						{/* { backToWork &&
							<div className={classes.covidDisclaimer}>
								<FormControl component="div" className={classes.covidFormControl}>
									<RadioGroup
										name={"acceptedCovid"}
										value={participant.acceptedCovid ? participant.acceptedCovid.key : ''}
										onChange={this.handleSurveyChange}
										className={classes.covidRadioGroup}
									>
										<FormControlLabel
											control={<Radio required={true}/>}
											value={SurveyAnswer.YES.key}
											label={t(SurveyAnswer.YES.label)}
										/>
										{participantType !== 4 &&
											<FormControlLabel
												control={<Radio required={true}/>}
												value={SurveyAnswer.NO.key}
												label={t(SurveyAnswer.NO.label)}
											/>
										}
										<Typography className={classes.covidFormLabel}>{t("participantInfo.covidAttestation")}</Typography>
									</RadioGroup>
								</FormControl>
							</div>
						}
						{ backToWork &&
							<div className={classes.disclaimer}>
								<Typography variant="body2" data-auto="covid-text">
									{participantType === 3 ? t("participantInfo.covidAltText") : t("participantInfo.covidText")}
								</Typography>
							</div>
						} */}

					
						<div className={classes.formActions}>
							<Button
								variant="contained"
								color="default"
								onClick={this.goBack}
								className={classes.button}
								data-auto="go-back"
								disabled={this.props.locked}
							>
								<PrevIcon className={classes.icon} />
								{t("common.goBack")}
							</Button>

							<Button
								type="submit"
								disabled={backToWork ?
									!participant.acceptedTerms || !participant.covid || this.props.locked :
									!participant.acceptedTerms || this.props.locked
								}
								variant="contained"
								color="primary"
								className={classes.button}
								data-auto="form-submit"
							>
								{t("common.submit")}
							</Button>
						</div>
					</form>

				</div>
			)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			[theme.breakpoints.up('md')]: {
				padding: '0 5%',
			},

			[theme.breakpoints.down('sm')]: {
				padding: '0 2%',
			},
		},
		infoContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			overflow: 'auto',
		},
		infoItem: {
			[theme.breakpoints.up('xl')]: {
				flex: '1 0 32%',
			},
			[theme.breakpoints.up('md')]: {
				flex: '1 0 45%',
			},
			[theme.breakpoints.down('sm')]: {
				flex: '1 0 90%',
				padding: '1px 0',
			},
		},
		infoHeader: {
			padding: `${theme.spacing.unit * 1.5}px`,
		},
		divider: {
			margin: '15px 0',
		},
		inlineItem: {
			display: 'inline-block',
			[theme.breakpoints.down('sm')]: {
				display: 'block',
			},
		},
		form: {
			paddingBottom: `${theme.spacing.unit * 2}px`,
		},
		disclaimer: {
			display: 'flex',
			paddingBottom: `${theme.spacing.unit * 2}px`,
		},
		formControl: {
			flex: 1,
		},
		formActions: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		formLabel: {
			color: 'red',
		},
		button: {
			marginRight: `${theme.spacing.unit * 2}px`,
		},
		icon: {
			fontSize: theme.typography.fontSize,
			marginRight: theme.spacing.unit,
		},
		covidDisclaimer: {
			display: 'flex',
			paddingBottom: `${theme.spacing.unit * 2}px`,
		},
		covidFormControl: {
			display: 'flex',
		},
		covidFormLabel: {
			display: 'inline-flex',
			alignItems: 'center',
		},
		covidRadioGroup: {
			flexDirection: 'row',
		}
	})

export default withTranslation()(withStyles(styles)(ParticipantInfo))
