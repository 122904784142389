import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
	// Enables the i18next backend
	.use(Backend)
	// Enable automatic language detection
	.use(LanguageDetector)
	// Enables the hook initialization module
	.use (initReactI18next)
	.init({
		// Standard language used
		fallbackLng: 'en',
		debug: false,
		// Detects and caches a cookie from the language provided
		detection: {
			order: ['queryString', 'cookie'],
			cache: ['cookie']
		},
		interpolation: {
			escapeValue: false
		},
		react: {
			useSuspense: true,
		},
	});

export default i18n;
