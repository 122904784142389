export default class Gender {
  static readonly FEMALE  = new Gender('FEMALE', "gender.female");
  static readonly MALE = new Gender('MALE', "gender.male");
  static readonly TRANS_FEMALE = new Gender('TRANS_FEMALE', "gender.transFemale");
  static readonly TRANS_MALE = new Gender('TRANS_MALE', "gender.transMale");
  static readonly NON_BINARY = new Gender('NON_BINARY', "gender.nonBinary");
  static readonly OTHER = new Gender('OTHER', "gender.other");
  static readonly DND  = new Gender('DND', "common.dnd");

  static all(){
    return [
      Gender.FEMALE,
      Gender.MALE,
      Gender.TRANS_FEMALE,
      Gender.TRANS_MALE,
      Gender.NON_BINARY,
      Gender.OTHER,
      Gender.DND
    ]
  }

  // private to disallow creating other instances of this type
  private constructor(public readonly key: string, public readonly label: any) {
  }

  toString() {
    return this.key;
  }
}
