import {
	Button,
	Collapse,
	createStyles,
	Divider,
	FormControl,
	FormLabel,
	InputAdornment,
	TextField,
	Theme,
	Typography,
	WithStyles,
	withStyles,
} from '@material-ui/core'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import NextIcon from '@material-ui/icons/NavigateNext'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Education from '../../../DataTypes/Education'
import EmploymentType from '../../../DataTypes/EmploymentType'
import {
	FormData,
} from '../../../DataTypes/PersonalInfoTypes'
import SurveyAnswer from '../../../DataTypes/SurveyAnswer'
import * as regexPatterns from '../../../services/utils/patterns'

import DatePicker from '../../Templates/DatePicker/DatePicker'
import SurveyRadioQuestion from './SurveyRadioQuestion'

interface SurveyDataProps extends WithStyles<typeof styles> {
	next: () => void
	back: () => void
	updateParticipantHandler: (formData: FormData) => void
	participant: FormData
	participantType?: number
	backToWork?: boolean
  t: any
}

export class SurveyData extends React.PureComponent<SurveyDataProps> {

	private formRef: React.RefObject<HTMLFormElement> = React.createRef()

	validate = () => {
		const form = this.formRef.current
		return form ? form.reportValidity() : false
	}

	onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const isFormValid = this.validate()

		if (isFormValid) {
			this.props.next()
		}
	}

	handleSurveyChange = (e: React.ChangeEvent<{}>) => {
		const { name, value } = e.target as HTMLSelectElement | HTMLInputElement;

		const newParticipantData = {
			...this.props.participant
		}

		newParticipantData[name] = SurveyAnswer[value];
		this.props.updateParticipantHandler(newParticipantData);
	}

	handleEducationChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement | HTMLInputElement;

		const newParticipantData = {
			...this.props.participant
		}
		newParticipantData.education = Education[value];

		if(newParticipantData.education !== Education.NO_HIGHSCHOOL){
			newParticipantData.highestGrade = undefined;
		}

		this.props.updateParticipantHandler(newParticipantData);
	}

	handleCitizenshipChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement | HTMLInputElement;

		const newParticipantData = {
			...this.props.participant
		}

		newParticipantData.citizenshipType = value;

		if(newParticipantData.citizenshipType === '1'){
			newParticipantData.alienNumber = undefined;
			newParticipantData.alienRegistrationExpirationDate = undefined;
		}

		this.props.updateParticipantHandler(newParticipantData);
	}

	handleValueChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
		const { name, value } = e.target

		const newParticipantData = {
			...this.props.participant
		}

		newParticipantData[name] = value;
		this.props.updateParticipantHandler(newParticipantData);
	}

	handleAlienExpirationDateChange = (date: Date) => {
		const newParticipantData = {
			...this.props.participant
		}

		newParticipantData.alienRegistrationExpirationDate = date;
		this.props.updateParticipantHandler(newParticipantData);
	}

	handleLayoffDateChange = (date: Date) => {
		const newParticipantData = {
			...this.props.participant
		}

		newParticipantData.layoffDate = date;
		this.props.updateParticipantHandler(newParticipantData);
	}

	handleEmploymentTypeChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement | HTMLInputElement;

		const newParticipantData = {
			...this.props.participant
		}

		newParticipantData.employmentType = EmploymentType[value];
		this.props.updateParticipantHandler(newParticipantData);
	}

	onCurrentlyEmployedRadioChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement;

		const newParticipantData = {
			...this.props.participant
		}

		if(value === SurveyAnswer.DND.toString()){
			newParticipantData.currentlyEmployed = SurveyAnswer.DND;
			newParticipantData.employmentType = undefined;
			newParticipantData.employer = undefined;
		}else if(value === SurveyAnswer.NO.toString()){
			newParticipantData.currentlyEmployed = SurveyAnswer.NO;
			newParticipantData.employmentType = undefined;
			newParticipantData.employer = undefined;
		}else if(value === SurveyAnswer.YES.toString()){
			newParticipantData.currentlyEmployed = SurveyAnswer.YES;
		}

		this.props.updateParticipantHandler(newParticipantData);
	}

	onBeingLaidOffRadioChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement;

		const newParticipantData = {
			...this.props.participant
		}

		if(value === SurveyAnswer.DND.toString()){
			newParticipantData.beingLaidOff = SurveyAnswer.DND;
			newParticipantData.layoffEmployer = undefined;
			newParticipantData.layoffDate = undefined;
		}else if(value === SurveyAnswer.NO.toString()){
			newParticipantData.beingLaidOff = SurveyAnswer.NO;
			newParticipantData.layoffEmployer = undefined;
			newParticipantData.layoffDate = undefined;
		}else if(value === SurveyAnswer.YES.toString()){
			newParticipantData.beingLaidOff = SurveyAnswer.YES;
		}

		this.props.updateParticipantHandler(newParticipantData);
	}

	goBack = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault()
		this.props.back()
	}

	render() {
		const { classes, participant, t } = this.props
		return (

			<form onSubmit={this.onFormSubmit} ref={this.formRef} className={classes.root} autoComplete="off">
				<Typography variant="h5" component="h1" data-auto="survey-header">
					{t("surveyData.title.employmentInformation")}:
				</Typography>
				<Divider variant="middle" className={classes.divider} />

				<SurveyRadioQuestion
          title={t("surveyData.title.currentlyEmployed")}
					propName="currentlyEmployed"
					onChange={this.onCurrentlyEmployedRadioChange}
					required={true}
					value={participant.currentlyEmployed ? participant.currentlyEmployed.key : ''}
					options={SurveyAnswer.all().map((sa: SurveyAnswer) => {
            return {value: sa.key, label: t(sa.label)};
          })}
				/>

				<Collapse in={participant.currentlyEmployed === SurveyAnswer.YES} data-auto="collapse-employed">
					<SurveyRadioQuestion
            title={t("surveyData.title.employmentType")}
						propName="employmentType"
						onChange={this.handleEmploymentTypeChange}
            options={EmploymentType.all().map((et: EmploymentType) => {
              return {value: et.key, label: t(et.label)};
            })}
						value={participant.employmentType ? participant.employmentType.key : ''}
					/>

					<TextField
						value={participant.employer || ''}
						id="employer"
						placeholder={t("surveyData.title.currentEmployer")}
						margin="dense"
						variant="outlined"
						InputProps={{
							inputProps: {
								title: t("surveyData.validation.employerName"),
								name: 'employer',
								maxLength: 120,
								pattern: regexPatterns.name,
							},
						}}
						className={classes.textField}
						onChange={this.handleValueChange}
					/>
				</Collapse>

				<SurveyRadioQuestion
					title={t("surveyData.title.beenLaidOff")}
					propName="beingLaidOff"
					onChange={this.onBeingLaidOffRadioChange}
					required={true}
					options={SurveyAnswer.all().map((sa: SurveyAnswer) => {
            return {value: sa.key, label: t(sa.label)};
          })}
					value={participant.beingLaidOff ? participant.beingLaidOff.key : ''}
				/>

				<Collapse in={participant.beingLaidOff === SurveyAnswer.YES} data-auto="collapse-layoff">
					<div className={classes.collapseItems}>
						<div className={classes.collapseItem}>
							<DatePicker
								required={false}
								id="layoff-date"
								label={t("surveyData.title.layOffDate")}
								onChange={this.handleLayoffDateChange}
								value={participant.layoffDate || undefined}
							/>
						</div>

						<div className={classes.collapseItem}>
							<TextField
								value={participant.layoffEmployer || ''}
								id="layoff-employer"
								placeholder={t("surveyData.title.employerName")}
								variant="outlined"
								InputProps={{
									inputProps: {
										title: t("surveyData.validation.employerName"),
										name: 'layoffEmployer',
										maxLength: 120,
										pattern: regexPatterns.name,
									},
								}}
								className={classes.layoffEmployer}
								onChange={this.handleValueChange}
							/>
						</div>
					</div>
				</Collapse>

				<SurveyRadioQuestion
					title={t("surveyData.title.higherWage")}
					propName="higherWage"
					onChange={this.handleSurveyChange}
					required={true}
					options={SurveyAnswer.all().map((sa: SurveyAnswer) => {
            return {value: sa.key, label: t(sa.label)};
          })}
					value={participant.higherWage ? participant.higherWage.key : ''}
				/>

				<SurveyRadioQuestion
					title={t("surveyData.title.betterSkills")}
					propName="betterSkills"
					onChange={this.handleSurveyChange}
					required={true}
					options={SurveyAnswer.all().map((sa: SurveyAnswer) => {
            return {value: sa.key, label: t(sa.label)};
          })}
					value={participant.betterSkills ? participant.betterSkills.key : ''}
				/>

				<SurveyRadioQuestion
					title={t("surveyData.title.familyDependent")}
					propName="familyDependent"
					onChange={this.handleSurveyChange}
					required={true}
					options={SurveyAnswer.all().map((sa: SurveyAnswer) => {
            return {value: sa.key, label: t(sa.label)};
          })}
					value={participant.familyDependent ? participant.familyDependent.key : ''}
				/>
				{/* {backToWork &&
					<SurveyRadioQuestion
						title={t("surveyData.title.covid")}
						propName="covid"
						onChange={this.handleSurveyChange}
						options={participantType === 4 ? [
								{ value: SurveyAnswer.YES.key, label: t(SurveyAnswer.YES.label) }] : [
								{ value: SurveyAnswer.YES.key, label: t(SurveyAnswer.YES.label) },
								{ value: SurveyAnswer.NO.key, label: t(SurveyAnswer.NO.label) }
							]
						}
						value={participant.covid ? participant.covid.key : ''}
						required={true}
					/>
				}
				<br /> */}

				<Typography variant="h5" component="h1" data-auto="survey-header">
          {t("surveyData.title.demographic")}:
				</Typography>
				<Divider variant="middle" className={classes.divider} />

				<FormControl className={classes.citizenshipFormControl}>
					<FormLabel data-auto="label-citizenship-type">{t("surveyData.title.citizenship")}: </FormLabel>
					<TextField
						required={true}
						name="citizenship-type"
						select={true}
						className="citizenshipType"
						InputProps={{
							inputProps: {
								title: t("surveyData.validation.citizenshipType"),
								name: 'citizenshipType'
							},
						}}
						onChange={this.handleCitizenshipChange}
						placeholder={t("surveyData.title.citizenshipStatus")}
						SelectProps={{
							native: true,
						}}
						label={t("surveyData.title.citizenshipType")}
						margin="dense"
						variant="outlined"
						value={participant.citizenshipType || ''}
					>
						<option value="" />
						{!participant.alien && <option value="1">{t("surveyData.title.citizen")}</option>}
						<option value="2">{t("surveyData.title.alien")}</option>
						<option value="3">{t("surveyData.title.permanent")}</option>
					</TextField>
				</FormControl>

				<Collapse
					in={participant.citizenshipType === '2' || participant.citizenshipType === '3'}
					data-auto="collapse-citizen"
				>
					<div className={classes.collapseItems}>
						<div className={classes.collapseItem}>
							<FormControl style={{ display: 'flex', flexDirection: 'column' }}>
								<FormLabel htmlFor="alienNumber" data-auto="citizen-label">
                  {t("surveyData.title.alienNumber")}
								</FormLabel>
								<TextField
                  required={participant.citizenshipType === '2' || participant.citizenshipType === '3'}
                  value={participant.alienNumber || ''}
									id="alienNumber"
									name="alienNumber"
									variant="outlined"
									className={classes.textField}
									InputProps={{
										inputProps: {
											title: t("surveyData.validation.alienNumber"),
											className: classes.input,
											name: 'alienNumber',
											pattern: regexPatterns.alienNumber,
											maxLength: 9,
										},
										startAdornment: <InputAdornment position="start">A-</InputAdornment>,
									}}
									onChange={this.handleValueChange}
								/>
							</FormControl>
						</div>

						<div className={classes.collapseItem}>
							<DatePicker
								id="alien-expiration-Date"
								label={t("surveyData.title.alienExp")}
								onChange={this.handleAlienExpirationDateChange}
								value={participant.alienRegistrationExpirationDate || undefined}
								required={participant.citizenshipType === '2' || participant.citizenshipType === '3'}
							/>
						</div>
					</div>
				</Collapse>

				<SurveyRadioQuestion
					title={t("surveyData.title.veteran")}
					propName="veteran"
					onChange={this.handleSurveyChange}
					required={true}
					options={SurveyAnswer.all().map((sa: SurveyAnswer) => {
            return {value: sa.key, label: t(sa.label)};
          })}
					value={participant.veteran ? participant.veteran.key : ''}
				/>

        <SurveyRadioQuestion
           title={t("surveyData.title.disabilityStatus")}
           propName="disabilityStatus"
           onChange={this.handleSurveyChange}
           required={true}
           options={SurveyAnswer.all().map((sa: SurveyAnswer) => {
             return {value: sa.key, label: t(sa.label)};
           })}
           value={participant.disabilityStatus ? participant.disabilityStatus.key : ''}
	         />

				<SurveyRadioQuestion
					title={t("surveyData.title.ell")}
					propName="ell"
					onChange={this.handleSurveyChange}
					required={true}
					options={SurveyAnswer.all().map((sa: SurveyAnswer) => {
            return {value: sa.key, label: t(sa.label)};
          })}
					value={participant.ell ? participant.ell.key : ''}
				/>

				<SurveyRadioQuestion
					title={t("surveyData.title.highest")}
					propName="education"
					onChange={this.handleEducationChange}
					options={[
						{ value: Education.NO_HIGHSCHOOL.key, label: t(Education.NO_HIGHSCHOOL.label) },
						{ value: Education.HIGH_SCHOOL.key, label: t(Education.HIGH_SCHOOL.label) },
						{ value: Education.GED.key, label: t(Education.GED.label) },
						{ value: Education.SOME_COLLEGE.key, label: t(Education.SOME_COLLEGE.label),},
						{ value: Education.VOCATIONAL.key, label: t(Education.VOCATIONAL.label) },
						{ value: Education.ASSOCIATE.key, label: t(Education.ASSOCIATE.label) },
						{ value: Education.BACHELORS.key, label: t(Education.BACHELORS.label) },
						{ value: Education.MASTERS.key, label: t(Education.MASTERS.label) },
						{ value: Education.PHD.key, label: t(Education.PHD.label) },
						{ value: Education.NA.key, label: t(Education.NA.label) }
					]}
					value={participant.education ? participant.education.key : ''}
				/>

				<Collapse in={participant.education === Education.NO_HIGHSCHOOL}>
          <TextField
            required={participant.education === Education.NO_HIGHSCHOOL}
						value={participant.highestGrade || ''}
						id="highestGrade"
						placeholder={t("surveyData.title.last")}
						margin="dense"
						variant="outlined"
						InputProps={{
							inputProps: {
								title: t("surveyData.validation.last"),
								name: 'highestGrade',
								maxLength: 2,
								pattern: regexPatterns.schoolGrade,
							},
						}}
						className={classes.textField}
						onChange={this.handleValueChange}
					/>
				</Collapse>

				<SurveyRadioQuestion
					title={t("surveyData.title.attendingSchool")}
					propName="attendingSchool"
					onChange={this.handleSurveyChange}
					required={true}
					options={SurveyAnswer.all().map((sa: SurveyAnswer) => {
            return {value: sa.key, label: t(sa.label)};
          })}
					value={participant.attendingSchool ? participant.attendingSchool.key : ''}
				/>

				<Collapse in={participant.attendingSchool === SurveyAnswer.YES}>
					<TextField
						value={participant.currentSchool || ''}
						id="currentSchool"
						placeholder={t("surveyData.title.currentSchool")}
						margin="dense"
						variant="outlined"
						InputProps={{
							inputProps: {
								title: t("surveyData.validation.currentSchool"),
								name: 'currentSchool',
								maxLength: 120,
								pattern: regexPatterns.name,
							},
						}}
						className={classes.textField}
						onChange={this.handleValueChange}
					/>
				</Collapse>

				<Divider variant="middle" style={{ margin: '12px 0' }} />

				<div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="primary"
						onClick={this.goBack}
						className={classes.button}
						data-auto="survey-info-back-button"
					>
						<PrevIcon className={classes.icon} />
						{t("common.goBack")}
					</Button>
					<Button
						disabled={false}
						variant="contained"
						color="primary"
						className={classes.button}
						data-auto="survey-info-next-button"
						type="submit"
					>
						{t("common.next")}
						<NextIcon className={classes.icon} />
					</Button>
				</div>
			</form>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			[theme.breakpoints.up('md')]: {
				padding: '2% 5%',
			},

			[theme.breakpoints.down('sm')]: {
				padding: '2%',
			},
		},
		infoHeader: {
			padding: `${theme.spacing.unit * 1.5}px`,
		},
		divider: {
			margin: '15px 0',
		},
		formControl: {
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		radioGroup: {},
		radioItem: {},
		textField: {
			minWidth: '300px',
			marginLeft: '12px',
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		icon: {
			fontSize: theme.typography.fontSize,
			marginRight: theme.spacing.unit,
		},
		button: {
			marginLeft: `${theme.spacing.unit * 2}px`,
		},
		inputSelect: {
			flexDirection: 'row',

			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				marginTop: `${theme.spacing.unit * 2}px`,
				flexBasis: '50%',
			},

			[theme.breakpoints.down('sm')]: {
				flexBasis: '100%',
			},
		},
		collapseItems: {
			display: 'flex',
			justifyContent: 'space-around',
			flex: 1,
			flexWrap: 'wrap',
		},
		collapseItem: {
			flexBasis: '45%',

			[theme.breakpoints.down('sm')]: {
				flexBasis: '100%',
			},
		},
		input: {},
		contactItem: {
			flexBasis: '48%',

			[theme.breakpoints.down('sm')]: {
				flexBasis: '98%',
			},
		},
		citizenshipType: {},
		citizenshipFormControl: {
			display: 'flex',
			width: '400px',
		},
		layoffEmployer: {
			[theme.breakpoints.up('md')]: {
				paddingTop: `${theme.spacing.unit * 2}px`,
			},
		},
	})

export default withTranslation()(withStyles(styles)(SurveyData))
