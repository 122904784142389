import { createStyles, TextField, Theme, WithStyles, withStyles } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { withTranslation } from 'react-i18next'

interface TextItemProps extends WithStyles<typeof styles> {
	label: string
	onChange: (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	SelectProps?: { [key: string]: any }
	otherInputProps?: { [key: string]: any }
	prop: string
	required?: boolean
	value: string
	textFieldProps?: { [key: string]: any }
	children?: ReactNode
	isSelect?: boolean
	t: any
}

const TextItem: React.FC<TextItemProps> = (props: TextItemProps) => {
	const {
		classes,
		isSelect,
		label,
		onChange,
		prop,
		required,
		textFieldProps,
		value,
		otherInputProps,
		SelectProps,
    t
	} = props

	return (
		<TextField
			select={!!isSelect}
			label={label}
			value={value}
			id={prop}
			fullWidth={true}
			required={!!required}
			className={classes.personalInfoItem}
			placeholder={label}
			margin="normal"
			variant="outlined"
			InputProps={{
				inputProps: {
					title: `${t("customTextItem.titleOne")} ${label}${t("customTextItem.titleTwo")}`,
					className: classes.input,
					name: prop,
					...otherInputProps,
				},
				...textFieldProps,
			}}
			SelectProps={SelectProps}
			onChange={onChange(prop)}
		>
			{props.children}
		</TextField>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		personalInfoItem: {},
		input: {},
	})

export default withTranslation()(withStyles(styles)(TextItem))
