import React from 'react'
import {withTranslation} from "react-i18next";

import {
	CircularProgress,
	createStyles,
	Step,
	StepLabel,
	Stepper,
	Theme,
	Typography,
	WithStyles,
	withStyles } from '@material-ui/core'

import moment from 'moment'
import Education from '../../DataTypes/Education'
import { FormData } from '../../DataTypes/PersonalInfoTypes'
import Race from '../../DataTypes/Race'
import SurveyAnswer from '../../DataTypes/SurveyAnswer'
import { ActivityOrNull } from '../../services/Auth/authentication.service'
import { buildPayload, encryptPayload } from '../../services/Encryption/encryption.service'
import { createNewParticipant } from '../../services/Participant/participant.service'
import FileUploadForm from '../Forms/FileUploadForm/FileUpload'
import PersonalInfoForm from '../Forms/PersonalInfoForm/PersonalInfoForm'
import SurveyData from '../Forms/SurveyData/SurveyData'
import CustomSlide from '../Templates/CustomSlide/CustomSlide'
import Instructions from '../Templates/Instructions/Instructions'
import ConfirmParticipantInfo from '../Templates/ParticipantInfo/ParticipantInfo'

interface MainProps extends WithStyles<typeof styles> {
	activity: ActivityOrNull
  t: any
}

interface MainState {
	direction: 'right' | 'left'
	currentStep: number
	participantData: FormData
	loading: boolean
  finish: 'default' | 'enrollment' | 'failure'
}

const STEPS = [
  "main.step.instructions",
  "main.step.personal",
  "main.step.document",
  "main.step.employment",
  "main.step.review",
  "main.step.finish"
]


export class Main extends React.Component<MainProps, MainState> {
	public readonly state: MainState = {
		direction: 'left',
		currentStep: -1,
    finish: 'default',
    participantData: new FormData(
      this.props.activity ? this.props.activity.activityCode : '' ,
			this.props.activity ? this.props.activity.siteCode : '',
      this.props.activity ? this.props.activity.pk : '',
      Education.NA.key
    ),
		loading: false
	}

	setupInitialStep = () => {
		setTimeout(() => {
			this.setState({
				currentStep: 0,
			})
		}, 200)
	}

	updateParticipantData = (newParticipantData: FormData) => {
		this.setState({
			participantData: newParticipantData
		});
	}

	moveStep = (moveDirection: 'right' | 'left', step: number) => {
		this.setState({
			direction: moveDirection,
			currentStep: step
		});
		window.scrollTo(0, 0);
	}

	handleUpdateFailure = (e: Error) => {
		this.setState((prevState: MainState) => ({
      finish: 'failure',
			currentStep: prevState.currentStep + 1,
			direction: 'left',
			loading: false,
		}))
	}

	onNewParticipantSuccess = () => {
		const { search } = window.location

		this.setState((prevState: MainState) => ({
			finish: search && search.indexOf('?code=') >= 0 ? 'enrollment' : prevState.finish,
			currentStep: prevState.currentStep + 1,
			direction: 'left',
			loading: false,
			participantData: new FormData('', '', '', '') // Ensure participant data is removed after successful submit
		}))
	}

	handleFinalMessage = () => {
    switch (this.state.finish) {
      case 'enrollment':
        return 'main.enrollmentFinalMessage';
      case 'failure':
        return 'main.failureFinalMessage';
      default:
        return 'main.defaultFinalMessage';
    }
  }

  handleEndMessage = () => {
    switch (this.state.finish) {
      case 'default':
        return 'main.defaultEndMessage';
      case 'enrollment':
        return 'main.enrollmentEndMessage';
      default:
        return 'common.blank';
    }
  }

	encryptSensitiveData = async (participantForm: FormData) => {
		const employRIPaylod = await buildPayload({
			siteCode: participantForm.siteCode,
			alienRegistrationExpirationDate: participantForm.alienRegistrationExpirationDate,
			alienNumber: participantForm.alienNumber,
			dateOfBirth: participantForm.dateOfBirth,
			zip: participantForm.zip,
			firstName: participantForm.firstName,
			lastName: participantForm.lastName,
			ssn: participantForm.ssn,
			gender: participantForm.gender ? participantForm.gender.key : '',
			address1: participantForm.address1,
			city: participantForm.city,
			state: participantForm.state,
			idDoc: participantForm.idDoc,
			idType: parseInt(participantForm.idType || '', 10),
			citizenshipType: participantForm.citizenshipType,
			homeless: participantForm.homeless
		});
		const encryptedFields = encryptPayload(employRIPaylod, participantForm.pk);
		return encryptedFields;
	}


	handleParticipantSubmit = () => {

		this.setState({
			loading: true
		}, () => {

			const participantForm = {
				...this.state.participantData
			}

			const requestBody: any = {
				activityCode: participantForm.activityCode,
				firstName: participantForm.firstName,
				middleInitial: participantForm.middleInitial,
				lastName: participantForm.lastName,
				dateOfBirth: moment(participantForm.dateOfBirth).format("YYYY-MM-DD"),
				homeless: participantForm.homeless,
				address1: participantForm.address1,
		    address2: participantForm.address2,
		    city: participantForm.city,
		    state: participantForm.state,
		    zip: participantForm.zip,
		    mailingAddress1: participantForm.mailingAddress1,
		    mailingAddress2: participantForm.mailingAddress2,
		    mailingCity: participantForm.mailingCity,
		    mailingState: participantForm.mailingState,
		    mailingZip: participantForm.mailingZip,
		    phoneNumber: participantForm.phoneNumber ? participantForm.phoneNumber.replace(/-/g, '') : null,
		    email: participantForm.email,
		    races: participantForm.races.length > 0 ?
		    	participantForm.races.map((r: Race) => {
		    		return r.key;
		    	}) : null,
		    ethnicity: participantForm.ethnicity ? participantForm.ethnicity.key : null,
		    gender: participantForm.gender ? participantForm.gender.key : null,
        preferredLanguage: participantForm.preferredLanguage ? participantForm.preferredLanguage.key : null,
		    highestGrade: participantForm.highestGrade,
      	currentSchool: participantForm.currentSchool,
      	employer: participantForm.employer,
      	layoffEmployer: participantForm.layoffEmployer,
      	layoffDate: participantForm.layoffDate ? moment(participantForm.layoffDate).format("YYYY-MM-DD") : null,
        formLanguage: participantForm.formLanguage,
			}

			const surveyFields = ['veteran', 'ell', 'attendingSchool',
						'currentlyEmployed', 'beingLaidOff', 'higherWage', 'betterSkills', 'familyDependent', 'covid', 'working', 'acceptedCovid','disabilityStatus'];

			surveyFields.forEach((s) => {
				if(participantForm[s] === SurveyAnswer.YES){
					requestBody[s] = 1;
				} else if(participantForm[s] === SurveyAnswer.NO){
					requestBody[s] = 0;
				} else if(participantForm[s] === SurveyAnswer.DND){
					requestBody[s] = 2;
				} else {
					requestBody[s] = null;
				}
			});

			// Citizenship type
			if(!participantForm.citizenshipType){
				requestBody.citizen = null;
			} else if(participantForm.citizenshipType === '1'){
				requestBody.citizen = true;
			} else {
				requestBody.citizen = false;
			}

			// Employment type
			if(!participantForm.employmentType || participantForm.employmentType.toString() === 'NA'){
				requestBody.employmentType = null;
			} else{
				requestBody.employmentType = participantForm.employmentType.key;
			}

			// Education
			if(!participantForm.education || participantForm.education.toString() === 'NA'){
				requestBody.education = null;
			} else {
				requestBody.education = participantForm.education.key;
			}

			if(participantForm.ssn && participantForm.idDoc && participantForm.idType){
				this.encryptSensitiveData(participantForm).then((p) => {
					requestBody.encryptedKey = p.key;
					requestBody.encryptedPayload = p.payload;
					createNewParticipant(requestBody)
						.then(this.onNewParticipantSuccess)
						.catch(this.handleUpdateFailure);
				});
			} else{
					createNewParticipant(requestBody)
						.then(this.onNewParticipantSuccess)
						.catch(this.handleUpdateFailure);
			}
		});
	}

	componentDidMount() {
		this.setupInitialStep()
	}

	public render() {
		const { classes, activity, t} = this.props
		const { currentStep, participantData } = this.state

		return (
			<div className={classes.root}>
				<div hidden={!this.state.loading}>
					<CircularProgress className={classes.loader} size={50} />
				</div>
				<section>
					<Stepper
						activeStep={currentStep}
						alternativeLabel={true}
						style={{ backgroundColor: 'transparent' }}
						data-auto="stepper"
					>
						{STEPS.map((label) => (
							<Step key={label} data-auto={`step-${label}`} style={{ paddingRight: '8px'}}>
								<StepLabel classes={{ label: classes.stepLabel }} data-auto={`step-label-${label}`}>
									{t(label)}
								</StepLabel>
							</Step>
						))}
					</Stepper>

					<div className={classes.slidesContainer}>
						<CustomSlide slideStep={0} currentStep={currentStep}>
							<Instructions
								activityName={activity!.activityName}
								nextStep={() => {this.moveStep('left', 1)}} />
						</CustomSlide>

						<CustomSlide slideStep={1} currentStep={currentStep} >
							<PersonalInfoForm
								participant={participantData}
								updateParticipantHandler={this.updateParticipantData}
								next={() => {this.moveStep('left', 2)}}
								back={() => {this.moveStep('right', 0)}}
							/>
						</CustomSlide>



						<CustomSlide slideStep={2} currentStep={currentStep} >
							<FileUploadForm
								participant={participantData}
								updateParticipantHandler={this.updateParticipantData}
								next={() => {this.moveStep('left', 3)}}
								back={() => {this.moveStep('right', 1)}}
							/>
						</CustomSlide>



						<CustomSlide slideStep={3} currentStep={currentStep} >
							<SurveyData
								participant={participantData}
								participantType={activity!.participantType}
								backToWork={activity!.backToWork}
								updateParticipantHandler={this.updateParticipantData}
								next={() => {this.moveStep('left', 4)}}
								back={() => {this.moveStep('right', 2)}}
							/>
						</CustomSlide>



						<CustomSlide slideStep={4} currentStep={currentStep} >
							<ConfirmParticipantInfo
								participant={participantData}
								participantType={activity!.participantType}
								backToWork={activity!.backToWork}
								updateParticipantHandler={this.updateParticipantData}
								next={this.handleParticipantSubmit}
								back={() => {this.moveStep('right', 3)}}
								locked={this.state.loading}
							/>
						</CustomSlide>



						<CustomSlide slideStep={5} currentStep={currentStep} style={{ display: 'flex'}}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									minHeight: '25vh',
									justifyContent: 'center',
									alignItems: 'center',
									flex: 1
								}}
							>
								<Typography
									variant="h4"
									component="p"
									align="center"
									className={classes.typography}
									data-auto="end-message"
								>
									{t(this.handleFinalMessage())}
								</Typography>
								<Typography variant="subtitle1" align="center" className={classes.typography} data-auto="end-message">
                  {t(this.handleEndMessage())}
								</Typography>
							</div>
						</CustomSlide>
					</div>
				</section>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: '0 auto',
			padding: `${theme.spacing.unit}px} ${theme.spacing.unit * 2}px}`,
			display: 'flex',
		},
		slidesContainer: {
			flex: 1,
			justifyContent: 'row',
		},
		typography: {
			padding: `0 ${theme.spacing.unit * 1.5}px`,
			[theme.breakpoints.down('sm')]: {
				fontSize: theme.typography.h6.fontSize,
			},
		},
		stepLabel: {
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
		loader: {
     position: 'absolute',
     top: '50%',
     left: '50%',
     marginTop: '-20px',
     marginLeft: '-20px',
     zIndex: 2000
  	},
	})

export default withTranslation()(withStyles(styles)(Main))
