import {
  createStyles,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Theme,
  Typography,
  WithStyles, withStyles
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import * as React from 'react'
import { withTranslation } from 'react-i18next'

interface IDInfoProps extends WithStyles<typeof styles> {
  t: any
}

const IDInfo: React.FC<IDInfoProps> = (props: IDInfoProps) => {
  const { classes, t } = props

  return (
    <div className={classes.root}>
      <ExpansionPanel style={{ marginTop: '16px' }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">{t("idInfo.kind")}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography component="div">
            {t("idInfo.example")}:
 						<ul>
              <li>{t("identification.alien")}</li>
              <li>{t("identification.license")}</li>
              <li>{t("identification.passport")}</li>
              <li>{t("identification.school")}</li>
              <li>{t("identification.military")}</li>
            </ul>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">{t("idInfo.how")}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography component="div">
            {t("idInfo.easyWay")}
 						<br /> <br />
 						{t("idInfo.phoneOne")}
 						{t("idInfo.phoneTwo")}
 						<br /> <br />
 						{t("idInfo.computer")}
 						{t("idInfo.try")}:
 						<ul>
              <li>
                {t("idInfo.optionOneLineOne")}
                {t("idInfo.optionOneLineTwo")}
 							</li>
              <li>
                {t("idInfo.optionTwoLineOne")}
                {t("idInfo.optionTwoLineTwo")}
 							</li>
            </ul>
            <br />
            {t("idInfo.please")}
            {t("idInfo.drag")}
 						<br /><br />
            {t("idInfo.drag")}
 						<ul>
              <li>{t("idInfo.jpeg")}</li>
              <li>{t("idInfo.jpg")}</li>
              <li>{t("idInfo.png")}</li>
              <li>{t("idInfo.bmp")}</li>
              <li>{t("idInfo.pdf")}</li>
              <li>{t("idInfo.tiff")}</li>
            </ul>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Divider variant="middle" style={{ margin: '12px 0' }} />
    </div>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {}
  })

export default withTranslation()(withStyles(styles)(IDInfo))
