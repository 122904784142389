import { indigo } from '@material-ui/core/colors'
import { createMuiTheme, createStyles, MuiThemeProvider, WithStyles, withStyles } from '@material-ui/core/styles/'
import React from 'react'
import './App.css'
import Bootstrap from './components/Bootstrap'
import GlobalStyles from './GlobalStyles'

interface AppProps extends WithStyles<typeof styles> {}

const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
	},
	palette: {
		type: 'light',
		primary: indigo,
	},
})
export class App extends React.Component<AppProps> {
	public render() {
		return (
			<MuiThemeProvider theme={theme}>
				<GlobalStyles />
				<div className={this.props.classes.root} data-auto="main-content-wrapper">
					<Bootstrap />
				</div>
			</MuiThemeProvider>
		)
	}
}

const styles = createStyles({
	root: {
		position: 'absolute',
		height: '100vh',
		width: '100vw',
	},
})

export default withStyles(styles)(App)
