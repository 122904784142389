import moment from 'moment'
import Forge from 'node-forge'



export const buildPayload = async (payloadData: any) => {

	if(payloadData.ssn.indexOf('-') >= 0){
		payloadData.ssn = payloadData.ssn.split('-').join('');
	}

	const idDocSplit = payloadData.idDoc.name.split('.');
	const payload: any = {
		UserMatch: {
			SiteCode: payloadData.siteCode,
			DateOfBirth: moment(payloadData.dateOfBirth).format('YYYY-MM-DDT00:00:00'),
			ZipCode: payloadData.zip,
			FirstName: payloadData.firstName,
			LastName: payloadData.lastName,
			SSN: payloadData.ssn,
			Gender: payloadData.gender === 'FEMALE' ? '0' : payloadData.gender === 'MALE' ? '1' : '9',
		},
		UIRegistration: {
			Address1: payloadData.address1,
			City: payloadData.city,
			StateAbbr: payloadData.state,
			MailZipCode: ""
		},
		AddlOptions: { DoNoUserUpdatesOnlyDocuments: true },
		FileAttachmentList: [
			{
				FileName: payloadData.idDoc.name,
				FileExt: '.' + idDocSplit[1],
				FileContents: await convertFileToBase64(payloadData.idDoc).then((data) => data.split(',')[1]),
				DocumentCategory: 4,
				DocumentClass: payloadData.idType,
				DocumentTag: payloadData.firstName + ' ' + payloadData.lastName + ' Verification'
			},
		],
	}


	if(payloadData.homeless){
		payload.UIRegistration.Homeless = '1';
		if(!payload.UIRegistration.Address1 || payload.UIRegistration.Address1 === ''){
			payload.UIRegistration.Address1 = 'Homeless';
		}

		if(!payload.UIRegistration.City || payload.UIRegistration.City === ''){
			payload.UIRegistration.City = 'Homeless';
		}

	}

	if(payloadData.citizenshipType){
		payload.UIRegistration.Citizen = payloadData.citizenshipType
	}


	if(payloadData.alienNumber && payloadData.alienRegistrationExpirationDate){
		payload.UIRegistration.AlienRegistrationNumber = payloadData.alienNumber
		// NOTE: "Alient"RegistrationExpirationDate is not a typo, it's what EmployRI's API expects:
		// https://uat-rest-vos44000000.geosolinc.com/rsRegistration/Help/Api/POST-api-Registration
		payload.UIRegistration.AlientRegistrationExpirationDate = moment(
			payloadData.alienRegistrationExpirationDate
		).format('YYYY-MM-DDT00:00:00')
	}

	return payload
}

export const convertFileToBase64 = async (file: File): Promise<string> => {
	return new Promise((resolve) => {
		const fileReader = new FileReader()
		fileReader.readAsDataURL(file)
		fileReader.onload = () => {
			resolve(
				typeof fileReader.result! === 'string' ? fileReader.result : String.fromCharCode.apply(null, fileReader.result as any),
			)
		}
	})
}

export const encryptPayload = (payload: any, pk: string) => {
	const rsaPublicKey =
		// tslint:disable-next-line:max-line-length
		'-----BEGIN PUBLIC KEY-----\n' +
		pk +
		'\n-----END PUBLIC KEY-----';

	const { util, cipher, pki, random } = Forge

	// Create AES 256 key (32 b)
	const key = random.getBytesSync(32)

	// Generate IV based on the first 16b of the key
	const iv = util.hexToBytes(util.bytesToHex(key).slice(0,32));


	// Create AES w/ CBC padding cipher via Forge lib
	const c = cipher.createCipher('AES-CBC', key)

	// console.log(JSON.stringify(payload));
	// Use cipher to encrypt our stringified payload
	c.start({ iv })
	c.update(util.createBuffer(JSON.stringify(payload)))
	c.finish()

	// Encode payload as b64 for transport
	const encryptedPayloadString = util.encode64(c.output.data)

	// Encrypt the AES key previously generated with master public key
	const publicKey: any = pki.publicKeyFromPem(rsaPublicKey)
	const encryptedKey = util.encode64(publicKey.encrypt(key))

	return {
		key: encryptedKey,
		payload: encryptedPayloadString,
	}
}
