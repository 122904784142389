import { Card, CardContent, CardHeader, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'

import { blue, grey } from '@material-ui/core/colors'
import { ActivityDetails } from '../../../services/Auth/authentication.service'
import SignUpForm from '../../Forms/SignupForm/SignupForm'

interface SignUpLandingProps extends WithStyles<typeof styles> {
	title: string
	onCodeValidation: (activity: ActivityDetails) => void
}

const SignUpLanding: React.FC<SignUpLandingProps> = (props: SignUpLandingProps) => {
	const { classes, title, onCodeValidation } = props

	return (
		<div className={classes.root}>
			<section className={classes.cards}>
				<Card className={classes.card}>
					<CardHeader
						className={classes.cardHeader}
						title={title}
						titleTypographyProps={{
							component: 'h2',
							variant: 'h5',
							align: 'center',
							color: 'inherit',
						}}
					/>
					<CardContent>
						<SignUpForm onCodeValidation={onCodeValidation} />
					</CardContent>
				</Card>
			</section>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		cards: {
			marginTop: theme.spacing.unit * 5,
			display: 'flex',
			justifyContent: 'center',
		},
		card: {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},

			[theme.breakpoints.only('md')]: {
				width: '80%',
			},

			[theme.breakpoints.up('lg')]: {
				width: '50%',
			},
		},
		cardHeader: {
			backgroundColor: blue[900],
			color: grey[50],
		},
	})

export default withStyles(styles)(SignUpLanding)
