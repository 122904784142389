export default class EmploymentType {
  static readonly FULL_TIME  = new EmploymentType('FULL_TIME', 'employmentType.fullTime');
  static readonly PART_TIME = new EmploymentType('PART_TIME', 'employmentType.partTime');
  static readonly NA  = new EmploymentType('NA', 'common.dnd');


  static all(){
  	return [
  		EmploymentType.FULL_TIME,
  		EmploymentType.PART_TIME,
  		EmploymentType.NA,
  	]
  }

  // private to disallow creating other instances of this type
  private constructor(public readonly key: string, public readonly label: any) {
  }

  toString() {
    return this.key;
  }


}
