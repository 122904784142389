import {
	Button,
	createStyles,
	Theme,
	Typography,
	WithStyles,
	withStyles,
} from '@material-ui/core'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import NextIcon from '@material-ui/icons/NavigateNext'
import imageCompression from "browser-image-compression";
import React from 'react'
import { withTranslation } from 'react-i18next'

import { FormData } from '../../../DataTypes/PersonalInfoTypes'
import DocSubmitButton from './../../Templates/FileUpload/DocSubmitButton'
import DocumentTypePicker from './../../Templates/FileUpload/DocumentTypePicker'
import FilePreview from './../../Templates/FileUpload/FilePreview'
import IDInfo from './../../Templates/FileUpload/IDInfo'

interface FileUploadFormState {
	fileSizeWarning: boolean
	fileTypeWarning: boolean
}

interface FileUploadFormProps extends WithStyles<typeof styles> {
	participant: FormData
	t: any
	updateParticipantHandler: (participant: FormData) => void
	next: () => void
	back: () => void
}

export class FileUploadForm extends React.Component<FileUploadFormProps, FileUploadFormState> {

	public readonly state: FileUploadFormState = {
	  fileSizeWarning: false,
	  fileTypeWarning: false
	}

	private formRef: React.RefObject<HTMLFormElement> = React.createRef()
	private fileInputRef: React.RefObject<HTMLFormElement> = React.createRef()
	private idTypeRef: React.RefObject<HTMLFormElement> = React.createRef()

	validate = () => {
		const form = this.formRef.current
		return form && form.reportValidity()
	}

	handleIdTypeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newParticipantData = {
			...this.props.participant
		}
		newParticipantData.idType = e.target.value;
		this.props.updateParticipantHandler(newParticipantData);
	}

	handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const isFormValid = this.validate()

		if (isFormValid) {
			this.props.next();
		}
	}

  onIdDoc = async (e: React.FormEvent<HTMLDivElement>) => {
    const uploadFile = this.fileInputRef

    if (uploadFile && uploadFile.current && uploadFile.current.files) {
      let uploadedFile = uploadFile.current.files[0];
      let fileSizeCheck = uploadedFile.size < 2000000;
      const fileTypeCheck = /^image\/.*|.pdf$/.test(uploadedFile.type);
      const imageTypeCheck = /^image\/(jpeg|png)$/.test(uploadedFile.type);

      if (!fileSizeCheck && imageTypeCheck) {
        const options = {
          maxSizeMB: 2
        }
        try {
          const compressedFile = await imageCompression(uploadedFile, options);
          uploadedFile = compressedFile
          fileSizeCheck = uploadedFile.size < 2000000

        } catch (error) {
          console.log(error);
        }
      }

      if (fileSizeCheck && fileTypeCheck) {

        const newParticipantData = {
          ...this.props.participant
        }
        newParticipantData.idDoc = uploadedFile;
        this.props.updateParticipantHandler(newParticipantData);

        this.setState({
          fileSizeWarning: false,
          fileTypeWarning: false
        })
      } else if (!fileSizeCheck) {
        this.setState({
          fileSizeWarning: true,
        })
      } else if (!fileTypeCheck) {
        this.setState({
          fileTypeWarning: true,
        })
      }
    }
  }

	handleFileUpload = () => {
		if (this.fileInputRef && this.fileInputRef.current) {
			this.fileInputRef.current.click()
		}
	}

	handleFileRemove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault()
		const newParticipantData = {
			...this.props.participant
		}
		newParticipantData.idDoc = undefined;
		newParticipantData.idType = undefined;

		this.props.updateParticipantHandler(newParticipantData);
		this.setState({
			fileSizeWarning: false,
			fileTypeWarning: false
		})
	}

  renderWarning(message: string) {
    return (
      <Typography variant="h6" color="error">
        {message}
      </Typography>
    )
  }

  render() {
    const { back, classes, participant, t } = this.props
    const { idDoc, idType } = participant
    const { fileSizeWarning, fileTypeWarning } = this.state

		return (
			<form onSubmit={this.handleSubmit} className={classes.root} ref={this.formRef}>
				<div className={classes.formItemsWrapper}>
					<Typography variant="h6">{t("fileUpload.validId")}</Typography>
          <div className={classes.idDocSubmit}>
            {
              idDoc ?
                <FilePreview docName={idDoc.name} handleFileRemove={this.handleFileRemove} >
                  {{
                    docTypePicker: <DocumentTypePicker
                      required={true}
                      ref={this.idTypeRef}
                      value={participant.idType}
                      onChange={this.handleIdTypeChange}
                    />
                  }}
                </FilePreview> :
                <DocSubmitButton onChange={this.onIdDoc} onUpload={this.handleFileUpload} inputRef={this.fileInputRef} />
            }
          </div>


          {fileSizeWarning && this.renderWarning(t("fileUpload.sizeWarning"))}

          {fileTypeWarning && this.renderWarning(t("fileUpload.typeWarning"))}

          <IDInfo />


					<div className={classes.buttonContainer}>
						<Button
							variant="contained"
							color="primary"
              onClick={back}
							className={classes.button}
							data-auto="file-upload-back-button"
						>
							<PrevIcon className={classes.icon} />
							{t("common.goBack")}
						</Button>
						<Button
              disabled={!idDoc || !idType}
							variant="contained"
							color="primary"
							className={classes.button}
							data-auto="file-upload-next-button"
							type="submit"
						>
							{t("common.next")}
							<NextIcon className={classes.icon} />
						</Button>
					</div>
				</div>
			</form>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'center',
			justifySelf: 'center',
			padding: `${theme.spacing.unit * 2}px 0`,
			margin: '0 auto',

			[theme.breakpoints.up('lg')]: {
				width: '50%',
			},

			[theme.breakpoints.only('md')]: {
				width: '60%',
			},
			[theme.breakpoints.only('sm')]: {
				width: '75%',
			},
			[theme.breakpoints.only('xs')]: {
				width: '90%',
			},
		},
		icon: {},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		button: {
			marginLeft: `${theme.spacing.unit * 2}px`,
		},
    formItemsWrapper: {},
    idDocRow: {},
    idDocSubmit: {}
	})

export default withTranslation()(withStyles(styles)(FileUploadForm))
