export default class Language {
  static readonly ENGLISH = new Language('ENGLISH', "language.english");
  static readonly SPANISH  = new Language('SPANISH', "language.spanish");
  static readonly FRENCH = new Language('FRENCH', "language.french");
  static readonly PORTUGUESE  = new Language('PORTUGUESE', "language.portuguese");
  static readonly CHINESE = new Language('CHINESE', "language.chinese");
  static readonly ARABIC  = new Language('ARABIC', "language.arabic");
  static readonly HAITIAN_CREOLE = new Language('HAITIAN_CREOLE', "language.haitianCreole");
  static readonly KHMER  = new Language('KHMER', "language.khmer");
  static readonly ITALIAN = new Language('ITALIAN', "language.italian");
  static readonly OTHER  = new Language('OTHER', "language.other");


  static all(){
  	return [
      Language.ENGLISH,
      Language.SPANISH,
      Language.FRENCH,
      Language.PORTUGUESE,
      Language.CHINESE,
      Language.ARABIC,
      Language.HAITIAN_CREOLE,
      Language.KHMER,
      Language.ITALIAN,
      Language.OTHER,
  	]
  }

  // private to disallow creating other instances of this type
  private constructor(public readonly key: string, public readonly label: any) {
  }

  toString() {
    return this.key;
  }


}
