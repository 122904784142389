export default class Race {

  static readonly ASIAN  = new Race('ASIAN', "race.asian");
  static readonly ISLANDER = new Race('ISLANDER', "race.islander");
  static readonly INDIGENOUS  = new Race('INDIGENOUS', "race.indigenous");
  static readonly WHITE  = new Race('WHITE', "race.white");
  static readonly BLACK  = new Race('BLACK', "race.black");
  static readonly NA  = new Race('NA', "common.dnd");


  static all(){
  	return [
  		Race.ASIAN,
  		Race.ISLANDER,
  		Race.INDIGENOUS,
  		Race.WHITE,
      Race.BLACK,
      Race.NA
  	]
  }

  // private to disallow creating other instances of this type
  private constructor(public readonly key: string, public readonly label: any) {
  }

  toString() {
    return this.key;
  }
}
