import {
	Button,
	CircularProgress,
	createStyles,
	Snackbar,
	SnackbarContent,
	TextField,
	Theme,
	WithStyles,
	withStyles,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors/'
import ErrorIcon from '@material-ui/icons/Error'
import React from 'react'
import {withTranslation} from "react-i18next";


// TODO - swap isActiveCodeValid for getActivityDetailsByCode
import { ActivityDetails, getActivityDetailsByCode } from '../../../services/Auth/authentication.service'

interface SignUpFormProps extends WithStyles<typeof styles> {
	onCodeValidation: (activity: ActivityDetails) => void,
	t: any
}

interface SignUpFormState {
	activityCode: string
	openSnack: boolean
	loading: boolean
}

const INITIAL_STATE: SignUpFormState = {
	activityCode: '',
	openSnack: false,
	loading: false,
}

export class SignUpForm extends React.Component<SignUpFormProps, SignUpFormState> {
	public readonly state: SignUpFormState = INITIAL_STATE

	private formRef: React.RefObject<HTMLFormElement> = React.createRef()
	private inputRef: React.RefObject<HTMLFormElement> = React.createRef()

	validate = () => {
		const form = this.formRef.current

		return form ? form.reportValidity() : false
	}

	handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({ activityCode: e.target.value })
	}

	handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const isFormValid = this.validate()

		if (isFormValid) {
			this.handleSpinner('start')

			const activityDetails = await getActivityDetailsByCode(this.state.activityCode).catch((error) => null)

			if (activityDetails) {
				this.props.onCodeValidation(activityDetails)
			} else {
				const input = this.inputRef.current

				if (input) {
					setTimeout(() => {
						input.focus()
					}, 300)
				}

				this.setState({
					openSnack: true,
					loading: false,
				})
			}
		}
	}

	handleSpinner = (status: 'start' | 'end') => {
		this.setState({ loading: status === 'start' })
	}

	handleSnackClose = () => {
		this.setState({
			openSnack: false,
		})
	}

	render() {
		const { classes, t } = this.props;
		const { activityCode, loading, openSnack } = this.state
		return (
			<React.Fragment>
				{loading && <CircularProgress className={classes.loader} size={50} />}
				<form onSubmit={this.handleSubmit} className={classes.signUpForm} ref={this.formRef} autoComplete="off">
					<TextField
						disabled={loading}
						value={activityCode}
						autoFocus={true}
						fullWidth={true}
						id="activity-code"
						className={classes.textField}
						placeholder={t("signUpForm.placeholder")}
						margin="normal"
						variant="outlined"
						InputProps={{
							inputProps: {
								title:t("signUpForm.title"),
								className: classes.input,
								name: 'activityCode',
								maxLength: 30,
								pattern: '^[a-zA-Z0-9]+$',
								ref: this.inputRef,
							},
						}}
						required={true}
						onChange={this.handleInputChange}
					/>
					<Button
						disabled={loading}
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						data-auto="submit"
						type="submit"
					>
						{t("common.submit")}
					</Button>
				</form>
				<Snackbar
					className={classes.snackContainer}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={openSnack}
					onClose={this.handleSnackClose}
					autoHideDuration={2000}
				>
					<SnackbarContent
						className={classes.errorSnack}
						message={
							<span id="message-id" data-auto="snackbar-message">
								<ErrorIcon className={classes.icon} />
								{t("signUpForm.error")}
							</span>
						}
					/>
				</Snackbar>
			</React.Fragment>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		signUpForm: {
			display: 'flex',
			flexDirection: 'column',
		},
		textField: {},
		button: {
			width: '20%',
			margin: '0 auto',
			minWidth: '100px',
		},
		input: {
			textAlign: 'center',
		},
		errorSnack: {
			backgroundColor: red[600],
		},
		snackContainer: {
			color: theme.palette.grey[50],
		},
		icon: {
			fontSize: theme.typography.fontSize,
			marginRight: theme.spacing.unit,
		},
		loader: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: '-20px',
			marginLeft: '-20px',
		},
	})

export default withTranslation()(withStyles(styles)(SignUpForm))
