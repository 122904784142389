export default class SurveyAnswer {

  static readonly YES  = new SurveyAnswer('YES', 'surveyAnswer.yes');
  static readonly NO = new SurveyAnswer('NO', 'surveyAnswer.no');
  static readonly DND  = new SurveyAnswer('DND', 'common.dnd');


  static all(){
  	return [
  		SurveyAnswer.YES,
  		SurveyAnswer.NO,
  		SurveyAnswer.DND,
  	]
  }

  // private to disallow creating other instances of this type
  private constructor(public readonly key: string, public readonly label: any) {
  }

  toString() {
    return this.key;
  }
}
