import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core'
import CloudUpload from '@material-ui/icons/CloudUpload'
import * as React from 'react'
import { withTranslation } from 'react-i18next'

interface IDInfoProps extends WithStyles<typeof styles> {
  onChange: (e: React.FormEvent<HTMLDivElement>) => void,
  onUpload: () => void,
  inputRef: React.RefObject<HTMLFormElement>
  t: any
}

const IDInfo: React.FC<IDInfoProps> = (props: IDInfoProps) => {
  const { inputRef, onChange, onUpload, t } = props

  return (
    <FormControl onChange={onChange} variant="outlined">
      <InputLabel htmlFor="id-doc" style={{ display: 'none' }}>
        {t("docSubmitButton.documentFile")}
 								<Input
          id="id-doc"
          aria-describedby="id-doc-upload"
          inputProps={{
            type: 'file',
            accept: 'image/*, .pdf',
            style: { display: 'none' },
            placeholder: t("docSubmitButton.documentFile"),
          }}
          inputRef={inputRef}
        />
      </InputLabel>

      <Button
        variant="contained"
        color="default"
        onClick={onUpload}
        data-auto="file-upload-button"
      >
        <CloudUpload style={{ paddingRight: '5px' }} />
          {t("docSubmitButton.uploadFile")}
 			</Button>
      <FormHelperText id="id-doc-upload">{t("docSubmitButton.uploadValidation")}</FormHelperText>
    </FormControl>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {}
  })

export default withTranslation()(withStyles(styles)(IDInfo))
