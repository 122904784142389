import {
  createStyles,
  TextField,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core'
import * as React from 'react'
import { withTranslation } from 'react-i18next'

interface DocumentTypePickerProps extends WithStyles<typeof styles> {
  required?: boolean;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  ref: React.RefObject<HTMLFormElement>
  t: any
}

const DocumentTypePicker: React.FC<DocumentTypePickerProps> = (props: DocumentTypePickerProps) => {
  const { classes, onChange, ref, required, value, t } = props

  return (
    <TextField
      autoFocus={true}
      required={required}
      fullWidth={true}
      name="id-type"
      select={true}
      value={value || ""}
      className={classes.personalInfoItem}
      InputProps={{
        inputProps: {
          title: t("documentTypePicker.title"),
          className: classes.input,
          name: 'idType',
          ref,
          id: 'idType'
        },
      }}
      onChange={onChange}
      placeholder={t("documentTypePicker.placeholder")}
      SelectProps={{
        native: true,
      }}
      label={t("documentTypePicker.label")}
      margin="dense"
      variant="outlined"
    >
      {!value && <option value={undefined} />}
      <option value="2">{t("identification.alien")}</option>
      <option value="15">{t("identification.license")}</option>
      <option value="21">{t("identification.passport")}</option>
      <option value="29">{t("identification.school")}</option>
      <option value="41">{t("identification.other")}</option>
    </TextField>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    personalInfoItem: {},
    input: {}
  })

export default withTranslation()(withStyles(styles)(DocumentTypePicker))
