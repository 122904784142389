import axios from 'axios'

import {fakeAPICall} from '../utils/testUtils'

const { REACT_APP_CODE_CHECK_URL } = process.env

export interface ActivityDetails {
	activityId: number
	activityName: string
	activityCode: string
	backToWork: boolean
 	participantType: number
	siteCode: string
	pk: string
}

export type ActivityOrNull = ActivityDetails | null

export const getActivityDetailsByCode = async (code: string): Promise<ActivityOrNull> => {
	if (process.env.NODE_ENV !== 'production') {

		const FAKE_ACTIVITY = await fakeAPICall(() => ({
			activityId: 3,
			activityName: 'Help Desk Operator Training',
			activityCode: code,
			// tslint:disable-next-line:max-line-length
			pk: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCF0etyBJdqSaHUrlJTNBtvtOm0\nIo5gNdLcqS7Hqz882HNLXYkJ1A9QBMhhL7YFC4tufh951V3iBVt4WHoaMmd+CxGn\nvJ5NFhl1kd0WdXZpYlayjA8Tl4KcA/4X8MyZBphpkDOAW5B6XlHMuppCqivH6mXx\ny7/XY8YTLNWsbOhMNwIDAQAB',
			siteCode: 'site'
		}))

		return FAKE_ACTIVITY
	} else {
		try {
			const { data } = await axios.post(REACT_APP_CODE_CHECK_URL || '/', {
				activityCode: code,
			})
			return Object.assign(data, { activityCode: code })
		} catch (e) {
			return Promise.reject(e)
		}
	}
}
