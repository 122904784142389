import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Theme,
	WithStyles,
	withStyles } from '@material-ui/core/'
import React from 'react'
import {withTranslation} from "react-i18next";

import { ActivityDetails, ActivityOrNull } from '../services/Auth/authentication.service'
import { isIE } from '../services/utils/utils'
import { authProvider } from './HOCs/AuthProvider/AuthProvider'
import AppHeader from './Templates/Header/Header'
import MainLanding from './Templates/MainLanding/MainLanding'

const MainWithAuth = authProvider(MainLanding)
interface BootstrapProps extends WithStyles<typeof styles> {
	t: any
}

interface BootstrapState {
	currentActivity: ActivityOrNull
	confirmedWarningDialog: boolean
}

const INITIAL_STATE: BootstrapState = {
	currentActivity: null,
	confirmedWarningDialog: false
}

class Bootstrap extends React.Component<BootstrapProps, BootstrapState> {
	public readonly state: BootstrapState = INITIAL_STATE

	setCurrentActivity = (activity: ActivityDetails) => {
		this.setState({
			currentActivity: activity,
		})
	}

	public render() {
		const { classes, t } = this.props
		const { currentActivity, confirmedWarningDialog } = this.state

		return (
			<div className={classes.root}>
				<header className={classes.header}>
					<AppHeader />
				</header>
				<main className={classes.siteContent}>
					<MainWithAuth onValidActivityFound={this.setCurrentActivity} activity={currentActivity} />
				</main>
				<div>
					<Dialog
		        open={isIE && !confirmedWarningDialog}
		        keepMounted={true}
		        aria-labelledby="alert-dialog-slide-title"
		        aria-describedby="alert-dialog-slide-description"
		      >
		        <DialogTitle id="alert-dialog-slide-title">{t("bootstrap.dialogTitle")}</DialogTitle>
		        <DialogContent>
		          <DialogContentText id="alert-dialog-slide-description">
                {t("bootstrap.dialogContent")}
		          </DialogContentText>
		        </DialogContent>
		        <DialogActions>
		          <Button onClick={() => {this.setState({confirmedWarningDialog: true})}} color="primary">
              {t("bootstrap.dialogButton")}
		          </Button>
		        </DialogActions>
		      </Dialog>
				</div>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexFlow: 'column',
			flex: '1 100%',

			'& > *': {
				display: 'flex',
				flex: '0 0 auto',
			},
		},
		header: {},
		siteContent: {
			position: 'relative',
			alignSelf: 'center',
			minHeight: 'calc(100vh - 125px)',

			[theme.breakpoints.up('lg')]: {
				width: '70%',
			},
			[theme.breakpoints.up('md')]: {
				width: '80%',
			},
			[theme.breakpoints.only('sm')]: {
				width: '75%',
			},
			[theme.breakpoints.only('xs')]: {
				width: '90%',
			},
		},
	})

export default withTranslation()(withStyles(styles)(Bootstrap))
