import { CircularProgress, Snackbar, SnackbarContent } from '@material-ui/core'
import { red } from '@material-ui/core/colors/'
import ErrorIcon from '@material-ui/icons/Error'
import React from 'react'
import {withTranslation} from "react-i18next";

import {
	ActivityDetails,
	ActivityOrNull,
	getActivityDetailsByCode,
} from '../../../services/Auth/authentication.service'
import SignUpForm from '../../Templates/SignUpLanding/SignUpLanding'

interface AuthProviderProps {
	onValidActivityFound: (activity: ActivityDetails) => void,
  t: any
}

interface AuthProviderState {
	isLoggedIn: boolean
	currentActivity: ActivityOrNull
	loading: boolean
	codeError: boolean
}

const INITIAL_STATE: AuthProviderState = {
	isLoggedIn: false,
	codeError: false,
	loading: true,
	currentActivity: null,
}

export const authProvider = <P extends object>(Component: React.ComponentType<P>) => {
	return withTranslation()(class AuthProvider extends React.Component<P & AuthProviderProps, AuthProviderState> {
		public readonly state: AuthProviderState = INITIAL_STATE

		onValidActivityCode = (activity: ActivityOrNull) => {
			this.setState({
				isLoggedIn: !!activity,
				currentActivity: activity,
				loading: false,
			})
		}

		async componentDidMount() {
			const { search } = window.location

			if (!this.state.isLoggedIn && search && search.length > 5 && search.indexOf('?code=') >= 0) {
				const activityOrNull = await getActivityDetailsByCode(search.replace('?code=', '')).catch(() => {
					this.setState({
						loading: false,
						codeError: true,
					})
				})
				if (activityOrNull) this.onValidActivityCode(activityOrNull)

				return
			}

			this.setState({
				loading: false,
			})
		}

		onSnackbarClose = () => {
			this.setState({
				codeError: false,
			})
		}

		public render() {
			const { ...props} = this.props
			const { codeError, isLoggedIn, currentActivity, loading } = this.state

			return isLoggedIn ? (
				<Component {...props} activity={currentActivity} />
			) : (
				<React.Fragment>
					{loading ? (
						<CircularProgress
							size={50}
							style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								marginTop: '-20px',
								marginLeft: '-20px',
							}}
						/>
					) : (
						<SignUpForm title={this.props.t("authProvider.title")} onCodeValidation={this.onValidActivityCode} />
					)}
					{codeError && (
						<Snackbar
							style={{ color: '#FAFAFA' }}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							open={true}
							onClose={this.onSnackbarClose}
							autoHideDuration={2000}
						>
							<SnackbarContent
								style={{ backgroundColor: red[500] }}
								message={
									<span id="message-id" data-auto="snackbar-message">
										<ErrorIcon style={{ marginRight: '8px' }} />
										{this.props.t("authProvider.error")}
									</span>
								}
							/>
						</Snackbar>
					)}
				</React.Fragment>
			)
		}
	})
}
