import {
	createStyles,
	FormControl,
	FormLabel,
	TextField,
	Theme,
	withStyles,
	WithStyles } from '@material-ui/core'
import moment from 'moment'
import * as R from 'ramda'
import React from 'react'
import { withTranslation } from 'react-i18next'


interface CompatibilityDatePickerProps extends WithStyles<typeof styles>{
	id: string
	required: boolean
	label?: string
	value?: Date
	onChange: (e: Date) => void
	minDate?: Date
	maxDate?: Date
  t: any
}

interface CompatibilityDatePickerState {
	day?: string,
	month?: string,
	year?: string,
	years: number[]
}


const MONTHS = [
	{label: 'month.jan', month:'01'},
	{label: 'month.feb', month:'02'},
	{label: 'month.mar', month:'03'},
	{label: 'month.apr', month:'04'},
	{label: 'month.may', month:'05'},
	{label: 'month.jun', month:'06'},
	{label: 'month.jul', month:'07'},
	{label: 'month.aug', month:'08'},
	{label: 'month.sep', month:'09'},
	{label: 'month.oct', month:'10'},
	{label: 'month.nov', month:'11'},
	{label: 'month.dec', month:'12'},]
const DAYS = R.range(1, 32)


export class CompatibilityDatePicker extends
			React.Component<CompatibilityDatePickerProps, CompatibilityDatePickerState> {

	public readonly state: CompatibilityDatePickerState = {
		years: R.range(1900, (parseInt(moment().format('YYYY'), 10) + 10)).reverse()
	}

	componentDidMount() {
		const initialState: CompatibilityDatePickerState = {
			day: "",
			month: "",
			year: "",
			years: this.state.years
		};

		if(this.props.value){
			const date = moment(this.props.value);
			initialState.month = date.format("MM");
			initialState.day = date.format("D");
			initialState.year = date.format("YYYY");
		}

		if(this.props.minDate && this.props.maxDate){
			const high = parseInt(moment(this.props.maxDate).format('YYYY'), 10) + 1;
			const low = parseInt(moment(this.props.minDate).format('YYYY'), 10) + 1;
			initialState.years = R.range(low, high).reverse();
		} else if(this.props.minDate && !this.props.maxDate){
			const high = parseInt(moment().format('YYYY'), 10) + 10;
			const low = parseInt(moment(this.props.minDate).format('YYYY'), 10) + 1;
			initialState.years = R.range(low, high).reverse();
		} else if(this.props.maxDate && !this.props.minDate){
			const high = parseInt(moment(this.props.maxDate).format('YYYY'), 10) + 1;
			const low = 1900;
			initialState.years = R.range(low, high).reverse();
		}

		this.setState(initialState);
	}


	handleDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const newState = {...this.state};
		newState[name] = value;
		this.setState(newState, () => {
			if(this.state.year && this.state.day && this.state.month){
				const newDate = moment(this.state.year + "-" + this.state.month + "-" + this.state.day, 'YYYY-MM-D');
				if(newDate.isValid()){
					this.props.onChange(newDate.toDate());
				}
			}
		});
	}

	public render(){
		const { classes, t } = this.props;
		return (
			<div>
				<FormControl variant="outlined" className={classes.root}>
					{this.props.label && <FormLabel component="label">{this.props.label}</FormLabel>}

					<div className={classes.formItems} data-auto="date-wrapper">
						<TextField
							required={this.props.required}
							id={this.props.id + "-month"}
							name="month"
							select={true}
							className={classes.month}
							value={this.state.month || ''}
							onChange={this.handleDropdownChange}
							SelectProps={{
								native: true,
								MenuProps: {
									className: classes.menu,
								},
							}}
							label="Month"
							margin="dense"
							variant="outlined"
						>
							<option key='' value='' />
							{MONTHS.map((m: any) => (
								<option key={m.month} value={m.month}>
									{t(m.label)}
								</option>
							))}
						</TextField>

						<TextField
							required={this.props.required}
							name="day"
							id={this.props.id + "-day"}
							select={true}
							className={classes.day}
							value={this.state.day || ''}
							onChange={this.handleDropdownChange}
							SelectProps={{
								native: true,
								MenuProps: {
									className: classes.menu,
								},
							}}
							label="Day"
							margin="dense"
							variant="outlined"
						>
							<option key='' value='' />
							{DAYS.map((option: number) => (
								<option key={option} value={option}>
									{option}
								</option>
							))}
						</TextField>


						<TextField
							required={this.props.required}
							id={this.props.id + "-year"}
							name="year"
							select={true}
							className={classes.year}
							value={this.state.year || ''}
							onChange={this.handleDropdownChange}
							SelectProps={{
								native: true,
								MenuProps: {
									className: classes.menu,
								},
							}}
							label="Year"
							margin="dense"
							variant="outlined"
						>
							<option key='' value='' />
							{this.state.years.map((option: number) => (
								<option key={option} value={option}>
									{option}
								</option>
							))}
						</TextField>
					</div>
				</FormControl>
			</div>);
	}
}

const styles = (theme: Theme) => createStyles({
	root: {
		display: 'flex',
		flex: 1,
	},
	formItems: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
	},
	day: {
		flexBasis: '24%',
	},
	month: {
		flexBasis: '45%',
	},
	year: {
		flexBasis: '28%',
	},
	menu: {},
})

export default withTranslation()(withStyles(styles)(CompatibilityDatePicker))
