import {
	createStyles,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Theme,
	WithStyles,
	withStyles,
} from '@material-ui/core'
import * as React from 'react'

interface RadioItemProps extends WithStyles<typeof styles> {
	value: string | undefined
	onChange: (e: React.ChangeEvent<{}>) => void
	propName: string
	title: string
	options: RadioOption[]
	required?: boolean
  bulletPoints?:string[]
}

interface RadioOption {
	value: string
	label: string
}


const SurveyRadioQuestion: React.FC<RadioItemProps> = (props: RadioItemProps) => {
	const { classes, value, onChange, options, propName, required, title, bulletPoints } = props

  const bulletPointsHTML = bulletPoints && bulletPoints.length>0? <ul>
                         {bulletPoints.map((p)=>(<li key={p} className={classes.listItem}>{p}</li>))}
                       </ul> : ''

	return (
		<div className={classes.radioItem} data-auto={`${title} section`}>
			<FormControl className={classes.formControl} required={required}>
				<FormLabel data-auto={`label-${title}`}>{title}</FormLabel>
        {bulletPointsHTML}
				<RadioGroup
					aria-label={propName}
					name={propName}
					className={classes.radioGroup}
					value={value}
					onChange={onChange}
					data-auto={`radio-group-${propName}`}
				>
					{options.map((option: RadioOption, i: number) => (
						<FormControlLabel
							key={i}
							value={option.value}
							control={<Radio id={propName+"-"+i} required={required} data-auto={`ratio-for-${option.label}`} />}
							label={option.label}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		radioItem: {},
		formControl: {
			display: 'flex',
		},
		radioGroup: {
			flexDirection: 'row',
		},
    listItem:{
      color: 'rgba(0, 0, 0, 0.54)'
    }
	})

export default withStyles(styles)(SurveyRadioQuestion)
