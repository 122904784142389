import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import {withTranslation} from "react-i18next";
import { Route, Switch } from 'react-router'

import { ActivityOrNull } from '../../../services/Auth/authentication.service'
import Main from '../../Main/Main'
import NotFoundLanding from '../NotFoundLanding'

interface MainLandingProps extends WithStyles<typeof styles> {
	activity: ActivityOrNull,
	t: any
}

const MainLanding: React.FC<MainLandingProps> = (props: MainLandingProps) => {
	const { activity, classes } = props

	return (
		<div className={classes.root}>
			<Switch>
				<Route exact={true} path="/" render={(mainProps) => <Main {...mainProps} activity={activity} />} />
				<Route component={NotFoundLanding} />
			</Switch>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			flex: 1,
		},
	})

export default withTranslation()(withStyles(styles)(MainLanding))
