import { Button, createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core/'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

interface NotFoundLandingLandingProps extends WithStyles<typeof styles> {
  t: any
}
export type RouterProps = NotFoundLandingLandingProps & RouteComponentProps<{}> & WithStyles<{}>

export const NotFoundLanding: React.SFC<RouterProps> = (props: RouterProps) => {
	const { classes, history, t } = props

	return (
		<div className={classes.root} data-auto="not-found-wrapper">
			<Typography component="h1" align="center" className={classes.head} color="secondary">
				404
			</Typography>
			<Typography component="h6" variant="h2" align="center" className={classes.body} color="primary">
				{t("notFoundLanding.sorry")}
			</Typography>
			<div className={classes.buttonContainer}>
				<Button
					variant="contained"
					className={classes.button}
					onClick={history.goBack}
					data-auto="back-button"
					tabIndex={1}
				>
					<ArrowBackIcon className={classes.backIcon} /> Go Back
				</Button>
			</div>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			paddingBottom: theme.spacing.unit * 2,
		},
		head: {
			fontSize: theme.typography.fontSize * 15,
			fontWeight: 500,
			lineHeight: 1,

			[theme.breakpoints.down('sm')]: {
				fontSize: theme.typography.fontSize * 8,
			},
		},
		body: {
			fontSize: theme.typography.fontSize * 2,
			fontWeight: 400,
		},
		buttonContainer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
		},
		button: {
			marginTop: theme.spacing.unit * 2,
		},
		backIcon: {},
	})

export default withTranslation()(withStyles(styles)(NotFoundLanding))
