import {createStyles, TextField, Theme, withStyles, WithStyles} from '@material-ui/core'
import moment from 'moment'
import React from 'react'


interface DefaultDatePickerProps extends WithStyles<typeof styles>{
	id: string
	required: boolean
	label?: string
	value?: Date
	onChange: (e: Date) => void
	minDate?: Date
	maxDate?: Date
}

export class DefaultDatePicker extends React.PureComponent<DefaultDatePickerProps> {

	handleDatepickerChange = (e : React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange(moment(e.target.value).startOf('day').toDate());
	}

	public render(){
		const { classes } = this.props;
		return (
			<div>
			 <TextField
			 	fullWidth={true}
				required={this.props.required}
        id={this.props.id}
        label={this.props.label || ''}
        type="date"
        defaultValue={this.props.value ? moment(this.props.value).format('YYYY-MM-DD') : ''}
        onChange={this.handleDatepickerChange}
        className={classes.datePicker}
        InputLabelProps={{
					shrink: true,
				}}
				InputProps={{
					inputProps: {
						 max: this.props.maxDate ? moment(this.props.maxDate).format('YYYY-MM-DD') : '',
						 min: this.props.minDate ? moment(this.props.minDate).format('YYYY-MM-DD') : '',
						 style: {height: '2em'},
					},
				}}
      />
		</div>);
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flex: 1,
		},
		formItems: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap',
			justifyContent: 'space-between',
		},
		datePicker: {
			flexBasis: '100%',
			[theme.breakpoints.down('sm')]: {
				flexBasis: '100%',
			},
		},
		menu: {},
	})

export default withStyles(styles)(DefaultDatePicker)