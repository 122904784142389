import Education from './Education'
import EmploymentType from './EmploymentType'
import Ethnicity from './Ethnicity'
import Gender from './Gender'
import Language from "./Language";
import Race from './Race'
import SurveyAnswer from './SurveyAnswer'

export class FormData {
	activityCode: string
	siteCode: string
	pk: string
	acceptedTerms: boolean = false
	acceptedCovid?: SurveyAnswer
	dateOfBirth?: Date
	email?: string
	ethnicity?: Ethnicity
	firstName?: string
	gender?: Gender
  preferredLanguage?: Language
	lastName?: string
	middleInitial?: string
	alien?: boolean
	ssn?: string
	phoneNumber?: string
	races: Race[] = []
	homeless?: boolean
	address1?: string
	address2?: string
	city?: string
	state: StateIDs = StateIDs.RI
	zip?: string
	mailingAddress1?: string
	mailingAddress2?: string
	mailingCity?: string
	mailingState?: StateIDs
	mailingZip?: string
	sameAsHomeAddress?: boolean
	idDoc?: File
	idType?: string
	citizenshipType?: string
	alienNumber?: string
	alienRegistrationExpirationDate?: Date
	attendingSchool?: SurveyAnswer
	currentSchool?: string
	education?: Education
	ell?: SurveyAnswer
	familyDependent?: SurveyAnswer
	covid?: SurveyAnswer
	veteran?: SurveyAnswer
  disabilityStatus?: SurveyAnswer
	beingLaidOff?: SurveyAnswer
	betterSkills?: SurveyAnswer
	currentlyEmployed?: SurveyAnswer
	employmentType?: EmploymentType
	higherWage?: SurveyAnswer
	layoffDate?: Date
	working?: SurveyAnswer
	highestGrade?: number
	layoffEmployer?: string
	employer?: string
  formLanguage? : string

  constructor(activityCode: string, siteCode: string, pk: string, education: string) {
  	this.activityCode = activityCode;
  	this.siteCode = siteCode;
    this.pk = pk;
    this.education = Education[education]
  }
}

export interface ParticipantActivity {
	activityId: string
	activityDate: Date
	activityName: string
	addendumId: string
	partnershipId: string
	partnershipName: string
}


export enum StateIDs {
	AK = 'AK',
	AL = 'AL',
	AR = 'AR',
	AZ = 'AZ',
	CA = 'CA',
	CO = 'CO',
	CT = 'CT',
	DE = 'DE',
	FL = 'FL',
	GA = 'GA',
	HI = 'HI',
	IA = 'IA',
	ID = 'ID',
	IL = 'IL',
	IN = 'IN',
	KS = 'KS',
	KY = 'KY',
	LA = 'LA',
	MA = 'MA',
	MD = 'MD',
	ME = 'ME',
	MI = 'MI',
	MN = 'MN',
	MO = 'MO',
	MS = 'MS',
	MT = 'MT',
	NC = 'NC',
	ND = 'ND',
	NE = 'NE',
	NH = 'NH',
	NJ = 'NJ',
	NM = 'NM',
	NV = 'NV',
	NY = 'NY',
	OH = 'OH',
	OK = 'OK',
	OR = 'OR',
	PA = 'PA',
	RI = 'RI',
	SC = 'SC',
	SD = 'SD',
	TN = 'TN',
	TX = 'TX',
	UT = 'UT',
	VA = 'VA',
	VT = 'VT',
	WA = 'WA',
	WI = 'WI',
	WV = 'WV',
	WY = 'WY',
}

export const StateInitials: string[] = Object.keys(StateIDs).filter((i) => isNaN(+i))
