export default class Education {
  static readonly NA  = new Education('NA', 'common.dnd');
  static readonly NO_HIGHSCHOOL = new Education('NO_HIGHSCHOOL', 'education.noHigh');
  static readonly HIGH_SCHOOL  = new Education('HIGH_SCHOOL', 'education.high');
	static readonly GED = new Education('GED', 'education.ged')
	static readonly SOME_COLLEGE = new Education('SOME_COLLEGE', 'education.someCollege')
	static readonly VOCATIONAL = new Education('VOCATIONAL', 'education.vocational')
	static readonly ASSOCIATE = new Education('ASSOCIATE', 'education.associate')
	static readonly BACHELORS = new Education('BACHELORS', 'education.bachelors')
	static readonly MASTERS = new Education('MASTERS', 'education.masters')
	static readonly PHD = new Education('PHD', 'education.phd')

  // private to disallow creating other instances of this type
  private constructor(public readonly key: string, public readonly label: any) {
  }

  toString() {
    return this.key;
  }
}
